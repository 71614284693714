.checkBoxWrap {
  width: 100%;
  display: flex;
  justify-content: center;
}

.checkBox {
  padding: 0;
  justify-content: center;

  & > span:last-child {
    display: none;
  }
}

.disabled {
  color: var(--text03);
}

.titleWrap {
  display: flex;
  width: 100%;
  align-items: center;

  .title {
    width: 165px;
    text-align: left;
  }

  .titleCTA {
  }
}
