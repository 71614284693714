@import 'libs/assets/theme/spacing.scss';

@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/elevations.scss';

.organisation {
  background-color: var(--ui01);
  padding-right: var(--w4a-safe-area-right, 0px);
  padding-left: var(--w4a-safe-area-left, 0px);
}

.goBackButton {
  color: var(--text02);
}

.headerWrapper {
  height: calc(var(--headerHeight) + var(--w4a-safe-area-top, 0px));
  position: relative;

  &.downMd {
    height: calc(var(--headerHeight) + var(--w4a-safe-area-top, 0px));
  }
}

.headerInner {
  display: flex;
  color: var(--text02);
  align-items: center;
}
.header {
  position: absolute;
  top: var(--w4a-safe-area-top, 0px);
  left: 0;
  width: 100%;
  height: var(--headerHeight);
  padding: $spaceS 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: $spaceL;
  background-color: var(--ui01);
  @include mainHeadDropshadow;

  &.downMd {
    padding: 0;
  }
}
