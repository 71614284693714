.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - (2 * var(--modalMarginThreshold)));
  height: calc(100% - (2 * var(--modalMarginThreshold)));
  outline: none;
}

.fullscreen {
  width: 100%;
  height: 100%;
}
