@use '@work4all/assets/theme/breakpoints.scss';
@use '@work4all/assets/theme/mixins.scss';
@use '@work4all/assets/theme/elevations.scss';
@use '@work4all/assets/theme/typography.scss';

.root {
  padding: 1rem;
  @include typography.body1;
}

.message {
  color: var(--text01);
}

.button {
  @include typography.body1;
  color: var(--brand01);
  cursor: pointer;
  background: none;
  border: none;
}
