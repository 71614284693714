@import 'libs/assets/theme/typography.scss';

.icon {
  color: var(--text03);
}

.wrapper {
  position: relative;
}

.expandLess {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 100;
}

.label {
  @include caption;
  color: var(--text03);
}
