.page {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--ui01);
}

.progressWrapper {
  height: 100%;
  display: grid;
  place-items: center;
}
