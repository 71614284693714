@import '@work4all/assets/theme/spacing.scss';

.root {
  padding: $spaceXS 3.5rem;
  background-color: var(--brand02);
  display: flex;
  box-shadow: none;
  border-radius: 0;
}

.body2 {
  font-weight: 700;
}
