.actions-menu-mobile {
  ul[role='menu'] > div:first-child {
    display: none;
  }
}

.divider {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
