.layer {
  position: fixed;
  pointer-events: none;
  inset: 0;
  z-index: 9999;
}

.itemWrapper {
  position: absolute;
}
