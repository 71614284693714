@import '@work4all/assets/theme/spacing.scss';

.titleWrap {
  display: flex;
  align-items: center;
  cursor: pointer;
  align-items: stretch;
}

.title {
  padding-left: $spaceS;
  flex-grow: 1;
  display: flex;
  align-content: center;
}

.valuePreview {
  display: flex;
  align-items: center;
}

.filterValue {
  color: var(--text03);
  white-space: pre-wrap;
}

.expanderIcon {
  transition: transform 0.2s linear;
}

.expanderIconExpanded {
  transform: rotate(-90deg);
}
