.textInput {
  height: 100%;
  width: 100%;
  align-items: center;
  border-radius: 4px;
  text-overflow: ellipsis;
  color: var(--text01);
  font-size: 1rem;

  border: 0px;
  outline: none;
  background: var(--ui03);

  cursor: text;

  &:disabled {
    opacity: d;
  }

  &:is(:hover, :focus):not(:disabled),
  .placeholderRow &:not(:disabled) {
    border: 1px solid var(--brand01);
  }
}

.rightTextAlign {
  text-align: right;
}
