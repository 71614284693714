@import 'libs/assets/theme/typography.scss';

.bold {
  @include body2SemiBold;
  align-content: center;
  justify-items: center;
}
.inverse {
  color: var(--textInverse);
}

.wrap {
  display: flex;
}

.inlineBlock {
  display: inline-block;
}

.alignCenter {
  text-align: center;
}

@media (max-width: 600px) {
  .wrap {
    //flex-direction: column;

    align-items: center;
  }
}

.weekTimeSpan {
  margin-left: 1rem;
}

.switchers {
  display: flex;
}

.dateString {
  background-color: transparent;
  border: 0;
}

.labelButton {
  min-width: 1px;
  padding: 0.5rem;
}
