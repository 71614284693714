@import '@work4all/assets/theme/breakpoints.scss';

.wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.content-wrapper {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.left-panel {
  min-width: 22rem;
  padding: 1rem;
  border-right: 1px solid var(--ui04);
  @media (max-width: $sm) {
    display: none;
  }
  overflow: hidden;
  overflow-y: auto;
}

.right-content {
  height: 100%;
  width: 100%;
  background: var(--ui03);
}

.header-wrapper {
  display: flex;
  gap: 1rem;
}

.flex-icon {
  display: flex;
}

.mobile-error {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}
