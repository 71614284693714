@import 'libs/assets/theme/spacing.scss';

.button {
  position: relative;
  height: 100%;
  color: var(--text01);
  padding: $spaceS;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-size: 0.85rem;
  &--active {
    color: var(--projectPrimaryLightColor);
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: $spaceXXS;
      background-color: var(--projectPrimaryLightColor);
    }
  }
}
