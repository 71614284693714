@import 'libs/assets/theme/elevations.scss';

.popover-title {
  font-weight: 900;
}

.popover-closeIcon {
  fill: var(--text03);
}

.paper {
  @include elevation2;
}

.divider {
  border-color: var(--ui03);
  margin: 0 0.5rem;
  height: 1px;
}

.itemIcon {
  color: var(--text03);
}

.resetButton {
  column-gap: 0.5rem;
}

.itemText {
  display: flex;
  width: 100%;
}
