.text03 {
  color: var(--text03);
}

.maxHoursError {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.stats {
  display: flex;
  padding: 0.5rem 1rem;
  justify-content: space-between;
}

.stat {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
