.resourceTimeline {
  :global {
    .mbsc-schedule-color-text {
      text-align: center;
      padding-top: 0.75rem;
    }

    .mbsc-windows.mbsc-schedule-event-inner {
      width: 100%;
      text-align: center;
    }
  }
}
