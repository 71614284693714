.preview {
  &-container {
    position: relative;
    transform: scale(1.25);
  }

  &-text-container {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0.8);
  }
}
