@import 'libs/assets/theme/spacing.scss';

.content {
  max-width: 50rem;
  width: 100%;
}

.fab {
  position: fixed;
  bottom: var(--mobileNavigationHeight) + $spaceS;
  right: $spaceS;
}
