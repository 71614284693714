@import 'libs/assets/theme/spacing.scss';

.dialogWrapper {
  max-width: 40rem;
}
.backgroundShape {
  clip-path: ellipse(90% 50% at center top);

  @media (min-width: 600px) {
    clip-path: ellipse(60% 45% at center top);
  }

  @media (min-width: 1280px) {
    clip-path: ellipse(60% 35% at center top);
  }
}
.quickLinkBox {
  background-color: var(--ui01);
  border-radius: 4px;
  padding: 0.5rem;
}

.projectHoursMaxHeight {
  max-height: 80vh;
}

.outerWrap {
  align-items: center;
  height: auto;
  padding-top: var(--w4a-safe-area-top, 0px);
  padding-right: var(--w4a-safe-area-right, 0px);
  padding-left: var(--w4a-safe-area-left, 0px);
  position: relative;
}

.timeTracker {
  width: 100% !important;
}

.widgetWrap > div {
  transition: all 0.1s linear;
}

.hoveredWidget {
  border: 2px solid var(--ui04);
  border-radius: 0.5rem;

  & > div {
    padding: 2rem;
    overflow: hidden;
  }
}

.draggedWidget {
  z-index: 1000 !important;
  pointer-events: none;
}
