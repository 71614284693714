.bubble {
  position: fixed;
  background-image: url('../../../../../assets/img/bubble-2.png');
  background-position: center 0;
  background-size: cover;
  width: 120%;
  border-radius: 50%;
  height: 32rem;
  margin-left: -10%;
  margin-top: -9rem;
  z-index: -1;
  top: 0;
  transition: all 0.1s ease-out;
}

@media (max-width: 720px) {
  .bubble {
    margin-left: -25%;
    width: 150%;
  }
}
