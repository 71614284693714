@use '@work4all/assets/theme/breakpoints.scss';

.root {
  position: fixed;
  left: 0;
  right: 0;
  bottom: var(--w4a-safe-area-bottom, 0px);
  z-index: 3000;
  pointer-events: none;

  @media (min-width: breakpoints.$lg) {
    position: static;
    height: auto;
    background-image: none;
    pointer-events: all;
  }
}

.buttons {
  width: max-content;
  margin-left: auto;
  padding: 1rem;
  pointer-events: all;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;

  @media (min-width: breakpoints.$lg) {
    padding: 0;
  }
}
