@use '@work4all/assets/theme/breakpoints.scss';

.tabsWrapper {
  padding: 0 1rem;

  @media (min-width: breakpoints.$lg) {
    padding: 0 3.5rem;
    height: var(--headerHeight);
    display: flex;
    align-items: center;
  }
}

.linearProgress {
  position: absolute;
  width: 100%;
}
