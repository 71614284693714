.reactGrid {
  :global {
    .react-grid-item > .react-resizable-handle {
      z-index: 999 !important;
      visibility: hidden;
      background: none;
    }

    .react-grid-item:hover > .react-resizable-handle {
      visibility: visible;
    }

    .react-grid-item.react-grid-placeholder {
      background-color: transparent;
      border: 2px dashed var(--ui06);
      border-radius: 0.5rem;
    }

    .react-grid-item > .react-resizable-handle::before {
      transform: rotate(45deg);
      content: '';
      position: absolute;
      right: 6px;
      bottom: 3px;
      width: 6px;
      height: 15px;
      border-right: 1px solid var(--text03);
    }

    .react-grid-item > .react-resizable-handle::after {
      transform: rotate(45deg);
      content: '';
      position: absolute;
      right: 3px;
      bottom: 4px;
      width: 5px;
      height: 5px;
      border-right: 1px solid var(--text03);
      border-bottom: none;
    }
  }
}
