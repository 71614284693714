@import '@work4all/assets/theme/spacing.scss';

.pickerWrap {
  padding: $spaceS;
  display: flex;
  flex-direction: column;
  gap: $spaceS;
}

.shortcuts {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
}

.darkText {
  color: var(--text01);
}
