.root {
  display: flex;
  gap: 1rem;
  padding: 8px;

  .options {
    visibility: hidden;
  }
}

.root:hover {
  .options {
    visibility: visible;
  }
}

.content {
  flex: 1;
}

.header {
  display: flex;
  gap: 1rem;
}
