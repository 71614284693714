.secondaryInfo {
  color: var(--text03);
}

.strikeThrough {
  text-decoration: line-through;
}

.greyedStrikeThrough {
  text-decoration: line-through;
  text-decoration-color: var(--text03);
  * {
    color: var(--text03);
  }
}
