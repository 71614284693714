.image-preview {
  width: 100%;
  height: 100%;

  position: relative;
  overflow-y: auto;
  text-align: center;

  & > div {
    width: 100%;
    height: 100%;
  }
}

.image-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;

  img {
    max-height: 100%;
    max-width: 100%;
    border: 8px solid transparent;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.35));
  }
}
