.inputWrapper {
  :global {
    .fr-sticky-dummy {
      background-color: transparent !important;
    }

    .fr-view {
      padding: 3px 0 3px 0 !important;

      span {
        font-family: Roboto, Helvetica, Arial, sans-serif !important;
        font-size: 14px !important;
        line-height: 1.4375em;
      }
    }

    .fr-box {
      border: 0;
      background-color: transparent !important;
    }

    .fr-wrapper {
      padding: 0;
      min-height: unset !important;
    }
  }
}

.inputWrapper {
  padding-top: 0.75rem;
  padding-bottom: 1px;
  margin-bottom: -5px;
}

.html-input-label-actions {
  padding: 0.5rem 0 0 !important;
  display: flex;
  max-height: 2.5rem;
}
