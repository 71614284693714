@import '@work4all/assets/theme/elevations.scss';

.paper {
  height: 100%;
  max-width: 100%;
  border-radius: 0;
  box-shadow: 0;
  @include elevation6;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.text-02 {
  color: var(--text02);
}

.font-family {
  font-family: 'Roboto', sans-serif;
}

.userItem {
  display: flex;
  align-items: 'center';
  gap: 0.5rem;
  padding-top: 1rem;
}

.primaryInfo {
  color: var(--text02);
}

.secondaryInfo {
  color: var(--text03);
}
