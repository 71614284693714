.file-info-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 100%;

  padding: 0;
  margin: 0;
}

.group {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.divider {
  margin: 0 1rem;
}
