.normal {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: opacity 0.9s ease-out;
}

.doTransition {
  opacity: 0;
  background: white;
}
