.root {
  display: flex;
  padding: 0 1rem;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}

.title {
  flex: none;
}

.content {
  flex: none;
}
