.table {
  display: flex;
}

.justLabel {
  svg {
    width: 0;
  }
}
.customCell {
  min-height: 2rem;
}

.amount {
  text-align: right;
}
