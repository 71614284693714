.hideDefaultIcons {
  input {
    color: var(--text01);
  }
  svg {
    color: var(--text03);
  }
}
.disabled {
  input {
    color: var(--text03);
  }
}
