.root {
  display: flex;
  padding: 0.5rem;
  align-items: center;
}

.icon {
  flex: none;

  svg path {
    fill: #8b90a0;
  }
}

.text {
  padding: 1rem 0.5rem;
  color: #000000;
}
