.border {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1rem;
}

.menu-item {
  min-height: 2rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.popper {
  z-index: 10;
}

.not-bold {
  font-weight: 400;
}

.menu {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
