.root {
  height: 100%;
  background: var(--ui01);
  overflow: auto;
}

.header {
  padding: 1rem;
  display: flex;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;
  }
}

.dialogWrapper {
  max-width: 600px;
}
