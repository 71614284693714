@use '@work4all/assets/theme/variables.scss';

.root {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 8px;
  border-radius: 4px;
  text-transform: none;
  white-space: nowrap;
  min-width: min-content;

  &.active {
    color: #499ce0;
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background-color: currentColor;
      opacity: 0.1;
      pointer-events: none;
    }
  }
}

.error {
  color: var(--error) !important;
}

.badge {
  top: 0.25rem;
}
