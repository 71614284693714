@import '@work4all/assets/theme/typography.scss';
@import '@work4all/assets/theme/mixins.scss';

.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  @include body1;
  padding: 1rem;
  white-space: pre-wrap;
}
