.table-cell {
  padding: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;

  white-space: pre;
  overflow-y: auto;
  user-select: none;
}

:global {
  .inside-row {
    white-space: pre-line;
  }
}

.single-line {
  height: 1.25rem;
  overflow: hidden;
}

.multi-line {
  min-height: 100%;
}
