@import 'libs/assets/theme/spacing.scss';

.chip-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: $spaceXS;
  column-gap: $spaceXS;
  padding: $spaceS 0px 0px 0px;
}
