@use '@work4all/assets/theme/breakpoints.scss';
@use '@work4all/assets/theme/mixins.scss';
@use '@work4all/assets/theme/elevations.scss';
@use '@work4all/assets/theme/typography.scss';

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-height: 0;
  overflow: hidden;
  background-color: var(--ui01);
}

.header {
  @include mixins.mainHeadDropshadow;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  padding-top: calc(1rem + var(--w4a-safe-area-top, 0px));
}

.calendarWrapper {
  flex: 1;
}
