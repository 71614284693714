@use '@work4all/assets/theme/breakpoints.scss';

.root {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas: 'mainDiv' 'editor';

  @media (min-width: breakpoints.$lg) {
    grid-template-columns: 2fr auto;
    grid-template-areas:
      'mainDiv'
      'editor';
  }
}

.mainDiv {
  grid-area: mainDiv;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: 'message' 'details';

  @media (min-width: breakpoints.$lg) {
    grid-template-columns: 2fr auto 1fr;
    grid-template-areas: 'message devider details';
  }
}

.message {
  grid-area: message;
}

.divider {
  grid-area: divider;
  display: none;

  @media (min-width: breakpoints.$lg) {
    display: block;
  }
}

.details {
  grid-area: details;
}

.editor {
  grid-area: editor;
}

.sectionPadding {
  padding: 1rem;

  @media (min-width: breakpoints.$lg) {
    padding: 1rem 3.5rem;
  }
}
