.root {
  min-height: 3.5rem;
  max-height: 7rem;
  padding: 0.5rem 1rem;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'icon title title'
    'icon text timestamp';
  gap: 0;
  cursor: pointer;
}

.unread {
  background: #499ce024;
}
