.collapse {
  flex: 1;
  display: grid;
  grid-template-rows: auto 1fr;
}

.collapseContainer {
  display: grid;
}

.collapseWrapperInner {
  display: grid;
  grid-template-rows: auto 1fr;
}

.h100 {
  height: 100%;
}
