.horizontal-select {
  display: flex;
  position: relative;
  background-color: var(--ui03);
  border-radius: 0.5rem;
  height: 2.3rem;

  &::before {
    background-color: var(--ui01);
    content: '';
    position: absolute;
    height: 2rem;
    width: 2rem;
    top: 0.15rem;
    left: 0.15rem;
    border-radius: 0.5rem;
    transition: left 300ms ease;
  }

  &--selected {
    &-1 {
      &::before {
        left: 2.35rem;
      }
    }
    &-2 {
      &::before {
        left: 4.6rem;
      }
    }
    &-3 {
      &::before {
        left: 6.9rem;
      }
    }
    &-4 {
      &::before {
        left: 9.2rem;
      }
    }
  }

  button {
    padding: 0.25rem;
    width: 2.25rem;
    height: 2.25rem;
    &:hover {
      opacity: 0.8;
    }
  }

  &__icon {
    color: var(--brand03);
    &--selected {
      color: var(--brand01);
    }
  }
}
