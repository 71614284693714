.documentView {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-content: stretch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 960px) {
    display: block;
    overflow: auto;
  }
}

.wrapperRight {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
}
