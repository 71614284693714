.wrapper {
  background-color: var(--ui08);
  display: flex;
  height: 100%;
  flex-direction: column;
  > :last-child {
    flex: 1;
  }
}

.hist {
  padding: 1rem;
  background-color: var(--ui01);
}
