@use '@work4all/assets/theme/breakpoints.scss';
@use '@work4all/assets/theme/mixins.scss';
@use '@work4all/assets/theme/elevations.scss';
@use '@work4all/assets/theme/typography.scss';

$bg: #e6cd71;

.wrapper {
  padding: 1rem 1rem 0;

  @media (min-width: breakpoints.$lg) {
    padding: 0;
    position: absolute;
    z-index: 1000;
    inset: 0;
    pointer-events: none;
  }
}

.draggable {
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media (min-width: breakpoints.$lg) {
    width: 32rem;
    position: absolute;
    pointer-events: auto;
  }

  &.dragging {
    user-select: none;
  }
}

.textarea {
  width: 100%;
  padding: 1rem;
  flex: 1 0 auto;
  resize: none;

  @include typography.body1;

  color: #000;
  background-color: $bg;
  border: none;
  outline: none;
}

.body {
  flex: 1;
  background-color: $bg;
  white-space: pre-wrap;
  padding: 1rem;
  max-height: 26rem;
  overflow: auto;

  @include typography.body1;

  color: #000;
}

.header {
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: $bg;
  color: black;
}

.titleWrapper {
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (min-width: breakpoints.$lg) {
    cursor: move;
  }
}

.title {
  padding-left: 1rem;
  padding-right: 1rem;
}

.divider {
  border-color: var(--text03);
}

.footer {
  flex: none;
  min-height: 2.5rem;
  display: flex;
  align-items: stretch;
}

.footerContent {
  background-color: $bg;
  flex: 1;
  min-width: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.footerTextWrapper {
  padding: 0.5rem 1rem;
  color: grey;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.footerText {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footerCorner {
  width: 2.5rem;

  .cornerTop {
    height: calc(100% - 2.5rem);
    background-color: $bg;
  }

  .cornerBottom {
    width: 2.5rem;
    height: 2.5rem;
    background-color: $bg;
    clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
  }

  .cornerGraphic {
    width: 100%;
    height: 100%;
  }
}
