.user-icon-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.hist {
  padding: 0 1rem;
  padding-bottom: 2rem;
}

.transparentTable {
  *[role='row'] > div,
  *[role='row'],
  *[role='columnheader'] {
    background-color: var(--ui08);
  }

  // This code remove scroll from table and use bigger container scroll on Previews
  // *[role='rowgroup'] {
  //   > div {
  //     height: unset !important;
  //     > div {
  //       overflow-y: unset;
  //     }
  //   }
  // }
}
