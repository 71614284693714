@use '@work4all/assets/theme/breakpoints.scss';

.container {
  width: 16.4rem;
  height: 9.6rem;
  background-color: var(--ui01);
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 var(--elevationColor2);
  position: relative;

  @media (max-width: breakpoints.$sm) {
    width: unset;
  }

  &.selected,
  &:hover {
    cursor: pointer;
    background-color: rgba(73, 156, 224, 0.14);
    box-shadow: 0px 2px 10px 0px rgba(73, 156, 224, 0.62);
    border: 3px solid var(--brand01);
    // color: var(--textInverse);
    border-radius: 11px;

    .marker {
      padding: 0 0 3px 0;
    }
    .containerInner {
      padding: 0;
    }
  }
  .marker {
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--ui01);
    background-color: var(--ui05);
    font-size: 12px;
    &.selected {
      background-color: var(--brand01);
      color: var(--textInverse);
    }
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.containerInner {
  height: 100%;
  padding: 0 3px 3px 3px;
}

.containerGrid {
  display: grid;
  grid-template-columns: 50px auto 0;
  position: relative;
  padding-right: 10px;
}

.header {
  font-weight: 700;
}

.double {
  grid-column-start: 2;
  grid-column-end: 4;
}

.first {
  display: flex;
  justify-content: left;
  padding-left: 10px;
}

.last {
  display: flex;
  justify-content: flex-end;
}

.gray {
  color: var(--text03);
}

$leftPadding: 4px;
.longLine {
  position: absolute;
  border-left: 2px solid var(--text03);
  height: calc(100% - 2.5rem + 3px);
  transform: translate($leftPadding, 1.5rem);
}

.line {
  height: calc(100% - 1px);
  width: 50%;
  transform: translate($leftPadding, -50%);
  border-bottom: 2px solid var(--text03);
}

.firstLine {
  height: calc(50% - 1px);
  transform: translate($leftPadding, 0%);
}

.icon {
  position: absolute;
  transform: translate(-2rem, 0%);
  color: var(--text03);
  padding: 0;
}

.iconCollapsed {
  transform: rotate(-90deg);
}
