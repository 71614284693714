.input {
  background-size: 3rem 3rem;
  background-image: repeating-linear-gradient(
    to right,
    var(--ui04),
    var(--ui04) 1.3px,
    transparent 1.3px,
    transparent
  );

  font-size: 2rem;
  font-family: monospace;
  letter-spacing: 1.9rem;
  padding-left: 1rem;
  height: 3rem;
  text-overflow: clip;
  border: 1px solid var(--ui04);
  border-radius: 0.5rem;
  background-color: var(--ui02);
}
