@import 'libs/assets/theme/spacing.scss';

.navigation-item {
  display: flex;

  &__item {
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 0.875rem;
    line-height: 1.225rem;
    letter-spacing: normal;
    text-transform: uppercase;
    padding: $spaceXS $spaceS;
    border-radius: 0.3125rem;
    color: white;
    padding-left: $spaceS;
    padding-right: $spaceS;
    height: 2.5rem;

    &--active {
      background-color: #ffffff4b;

      &:hover {
        background-color: #ffffff8c;
      }

      & > span {
        display: block;
      }
    }
  }

  &__divider {
    margin: 0 $spaceXS;
    background-color: var(--ui01);
  }
}
