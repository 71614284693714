@use '@work4all/assets/theme/elevations.scss';

.textWrapper {
  padding: 0 1rem 0 0.5rem;
  display: flex;
  width: 100%;
  align-items: center;
  height: 3.75rem;
  border-bottom: 1px solid var(--widgetBorder);

  &.showFavorites {
    padding: 0 0.25rem 0 0.5rem;
  }
}

.list-item-button {
  height: 100%;
  max-width: 100%;
}

.list-item-content-wrapper {
  width: 100%;
  min-width: 0;
}

.nothing-found {
  text-align: center;
  font-style: italic;
}

.deselect-icon {
  color: var(--ui05);
  margin-left: 0.5rem;
  flex: none;
}

.list-item-button-active {
  background-color: var(--ui02);
}

.favActive {
  color: var(--brand02);
}
.chipListWrapper {
  padding: 0 0.5rem;
}

.settings-popover {
  margin: 0 0.3rem;
  @include elevations.elevation3;
}

.settings-paper {
  padding: 0 1rem;
  border-radius: 0.5rem;
}

.list-picker-filter-input {
  border-bottom: none !important;
}

.favorites-button {
  padding: 5px 7px;
  font-weight: 400;
  border-radius: 8px;
  color: var(--text02);

  &:hover {
    color: var(--brand01);
    border-color: var(--brand01);
  }
}

.favorites-button-selected {
  color: var(--brand01);
  background-color: rgba(73, 156, 224, 0.08);
  font-weight: bold;
}

.favorites-button-no-settings {
  margin-right: 0.5rem;
}
