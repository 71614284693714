.root {
  overflow-x: auto;
}

.inner {
  display: flex;
  padding: 0.5rem;
  width: max-content;
  gap: 0.5rem;
}
