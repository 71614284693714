@import '../../../../../../../variables.scss';

.left {
  display: flex;
  flex-direction: column;
}

.mobile {
  order: 1;
}

.desktop {
  flex-basis: 70%;
  width: 70%;
}
