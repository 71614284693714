@import 'libs/assets/theme/spacing.scss';

.popoverItem {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.75rem 1rem;
  color: var(--ui06);
  overflow-wrap: anywhere;
}

.folderIcon {
  flex: none;
  width: 1.5rem;
  height: 1.5rem;
}

.trigger {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-size: 0.875rem;
  line-height: 1.225rem;
  text-transform: uppercase;
  padding: $spaceXS $spaceS;
  border-radius: 0.3125rem;
  color: white;

  &.active {
    background-color: var(--ui01);

    & > span {
      @supports ((-webkit-background-clip: text) or (background-clip: text)) {
        color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
        background-image: url('../../../../../../../libs/assets/img/bubble.png');
      }
    }

    & > svg {
      color: var(--brand01);
    }
  }
}
