@import 'libs/assets/theme/typography.scss';

.divider {
  background-color: var(--ui03);
}

.item {
  padding: 0.75rem $spaceS;
  padding: 0.75rem 0;
  border-color: var(--ui03);
}

.itemText {
  margin: 0;
}

.primary {
  @include body2;
  color: #000;
  text-align: left;
}
