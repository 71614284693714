.checkBoxWrap {
  width: 100%;
  display: flex;
  justify-content: center;
}

.checkBox {
  padding: 0;
  justify-content: center;

  & > span:last-child {
    display: none;
  }
}
