.invis {
  visibility: hidden;
}

.textAreaWrapper {
  height: auto;
  align-items: flex-start;
}

.inputsWrapper {
  position: relative;
}

.searchBarWithDropDownWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
}
