.handle {
  height: 5px;
  background: var(--ui04);
  opacity: 0;
  cursor: row-resize;
  position: absolute;
  bottom: 0;
  width: 100%;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 1;
    filter: drop-shadow(0 0 5px var(--brand01));
    background: var(--brand01);
  }
}
