.root {
  padding-inline: 3rem;
}

.header {
  padding: 0;
  padding-inline: 1rem;
}
.minified {
  max-width: 450px;
  min-width: min(450px, 100vw);
  transition: min-width 1s;
}

.maximized {
  min-width: min(967px, 100vw);
  transition: min-width 1s;
}

.contractToDeliveryNote {
  min-width: min(1053px, 100vw);
}

.forbiddenSeeSellingPrices {
  min-width: min(689px, 100vw);
}

.groupByArticle {
  min-width: min(1079px, 100vw);
}

.collapse {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.collapseInner {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding-right: 2rem;
}
