$rowHeight: 2.5rem;
:export {
  maskOverlayRowHeight: $rowHeight;
}

.headerWrapper {
  height: auto;
  background-color: var(--ui01);
}

.hidden {
  display: none;
}

.headerRow {
  background-color: transparent;
  border-top: 0.0625rem solid var(--ui04);
  height: $rowHeight;
  // when using block layout make table always take the rest width.
  min-width: 100%;
}

.withBorderTop {
  border-top: 0.0625rem solid var(--ui04);
}

.headerCellHeading {
  font-size: 1rem;
  line-height: 1rem;
  color: var(--ui05);
  text-align: inherit;
}

.row {
  border: 0;
  height: 2.5rem;
}

.cell {
  font-size: 1rem;
}

.selection {
  color: var(--text03);
}
