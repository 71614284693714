@import 'libs/assets/theme/spacing.scss';
@import 'libs/assets/theme/typography.scss';
@import 'libs/assets/theme/mixins.scss';

.searchbar {
  background-color: var(--ui02);
  border: 1px solid var(--ui04);
  border-radius: $spaceXXS;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  margin-left: 0;
  display: flex;
  border-radius: 0.25rem;
  height: var(--projectSearchBarHeight);

  &__search-icon-wrapper {
    width: 2.8125rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__search-icon {
    color: var(--text03);
    pointer-events: none;
  }

  &__search-root {
    width: 100%;
    padding: 0;
  }

  &__input-el {
    @include body2;
    @include ellipsis;
    color: var(--text01);
    height: 100%;
    padding: 0;
    &::placeholder {
      color: var(--text03);
      opacity: 1;
    }
  }
}
