.custom {
  &-field {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  &-NUMERIC {
    justify-content: right;
  }
}
