.root {
  position: relative;
  width: 100%;
  height: 100%;
}

.img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.video {
  transform: none;
  width: auto;
  height: auto;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.imageMirror {
  transform: rotateY(180deg);
}

.invis {
  display: none;
}
