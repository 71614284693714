@import 'libs/assets/theme/breakpoints.scss';

.signature {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    border-radius: 0.5rem;
    border: 1px solid var(--ui05);
  }

  canvas {
    border-radius: 0.5rem;
  }
}

.dialog {
  max-width: 550px;
}

div.marker {
  max-height: 20px;
  pointer-events: none;
  border-radius: 0;
  border: none;
  position: absolute;
  display: flex;
  align-items: center;
  top: 70%;
  width: 90%;
  &__line {
    display: flex;
    justify-content: center;
    flex: 1;
    height: 1px;
    border-top: none;
    margin: 0.5rem;
  }

  &__indicator {
    display: flex;
    border: none;
    &-sign {
      max-height: 20px;
      background-color: var(--userShade6);
      color: white;
      border-radius: 0;
      border: none;
      font-family: Roboto;
      font-size: 11px;
      font-weight: 700;
      padding: 0.25rem;
      display: flex;
      align-items: center;
    }

    &-arrow {
      width: 0;
      height: 0;
      border-radius: 0;
      border-right: none;
      border-left: 10px solid var(--userShade6);
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
    }
  }
}

div.remove-default {
  z-index: 3;
  max-height: 20px;
  border-radius: 0;
  border: none;
  position: absolute;
  display: flex;
  align-items: center;
  top: 0.5rem;
  color: var(--text03);
  right: 0;
}

.options {
  height: 4rem;
  padding-top: 1rem;
  display: flex;
  align-items: center;
}

.defaultSignatureButton {
  border: 1px solid var(--brand01);
  width: 100%;
  border-radius: 0.5rem;
  // color: var(--text01);
  margin-bottom: 1rem;
}
