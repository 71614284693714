@import '@work4all/assets/theme/spacing.scss';

.panel {
  padding-top: $spaceS;
}

.restrictedWidth {
  max-width: 88rem;
  margin: 0 auto;
}
