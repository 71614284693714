.editWrap {
  position: relative;

  .iconContainer {
    position: absolute;
    top: 0;
    right: 100%;
    height: 100%;
    cursor: pointer;
    display: none;
    padding: 0 1rem;
  }

  &:not(.disabled) {
    &:hover {
      .iconContainer {
        display: block;
      }
    }
    .active {
      display: block;
    }
  }
}

.rotate {
  transform: rotate(180deg);
}
