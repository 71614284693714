@use '@work4all/assets/theme/typography.scss';

$primary-color: #007bff;
$font-stack: 'Roboto', sans-serif;
$font-size: 14px;

.markdown-content {
  // Reset default margin and padding
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  li {
    margin: 0;
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-stack;
    font-weight: bold;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  // Style links
  a {
    color: $primary-color;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  // Style headings
  h1 {
    @include typography.heading1;
  }

  h2 {
    @include typography.heading2;
  }

  h3 {
    @include typography.heading3;
  }

  h4 {
    @include typography.heading4;
  }

  h5 {
    font-size: 0.83rem;
  }

  h6 {
    font-size: 0.67rem;
  }

  p,
  blockquote,
  ul,
  ol,
  dl,
  table,
  pre,
  details {
    margin-bottom: 1rem;
  }

  // Style lists
  ul,
  ol {
    padding-left: 2rem;
  }

  ol ol,
  ul ol {
    list-style-type: lower-roman;
  }

  ul ul ol,
  ul ol ol,
  ol ul ol,
  ol ol ol {
    list-style-type: lower-alpha;
  }

  li {
    margin-bottom: 0.5rem;
  }

  code,
  kbd,
  pre,
  samp {
    font-family: Consolas, monospace;
    font-size: 1em;
  }

  // Style code blocks
  pre {
    background-color: #ddd;
    padding: 1rem;
    border-radius: 4px;
    overflow-x: auto;
  }

  // Style blockquotes
  blockquote {
    border-left: 4px solid var(--text02);
    padding-left: 1rem;
    margin: 0 0 1rem;
  }

  blockquote > :first-child {
    margin-top: 0;
  }

  blockquote > :last-child {
    margin-bottom: 0;
  }

  // Style inline code
  code {
    background-color: #ddd;
    padding: 0.2rem 0.4rem;
    border-radius: 4px;
  }

  mark {
    background-color: var(--ui04);
    color: var(--text02);
  }

  small {
    font-size: 90%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  img {
    display: inline-block;
    border-style: none;
    max-width: 100%;
    box-sizing: content-box;
    background-color: var(--ui01);
  }

  figure {
    margin: 1em 40px;
  }

  table {
    border-spacing: 0;
    border-collapse: collapse;
    display: block;
    width: max-content;
    max-width: 100%;
  }

  td,
  th {
    padding: 0;
  }

  a:not([href]) {
    color: inherit;
    text-decoration: none;
  }

  a.anchor {
    float: left;
    padding-right: 4px;
    margin-left: -20px;
    line-height: 1;
  }

  .anchor:focus {
    outline: none;
  }
}
