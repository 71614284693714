@use '@work4all/assets/theme/breakpoints.scss';

.root {
  position: relative;
  flex: 1;
  display: grid;
  overflow: auto;
}

.scroll {
  display: grid;
  overflow: auto;
  padding-bottom: calc(5rem + var(--w4a-safe-area-bottom, 0px));

  @media (min-width: breakpoints.$lg) {
    padding-bottom: 0;
  }
}

.disableScroll {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
