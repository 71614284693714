@use 'libs/assets/theme/variables.scss';

.cell {
  height: var(--cardWidgetRowHeightRem);
  display: flex;
  position: relative;
  align-items: center;

  padding-left: 12px;
  padding-right: 12px;

  white-space: nowrap;
  overflow: hidden;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;

  &:first-child {
    padding-left: 1rem;
  }

  &:last-child {
    padding-right: 1rem;
  }

  &.primary {
    color: var(--text01);
  }

  &.secondary {
    color: var(--text03);
  }

  &.left {
    justify-content: flex-start;
    min-width: 4rem;
  }

  &.center {
    justify-content: center;
  }

  &.right {
    justify-content: flex-end;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  .cell-content {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.row {
  width: 100%;
  text-decoration: none; //the row maybe a link which we do not want to have normal link styles

  &:hover > .cell {
    background-color: var(--ui03);
  }

  &.selected > .cell {
    background-color: var(--ui08);
  }

  &.done > .cell::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    border-bottom: 1px solid var(--text02);
  }

  &.done > .cell:first-child::after {
    left: 1rem;
  }

  &.done > .cell:last-child::after {
    right: 1rem;
  }

  &.inactive > .cell {
    color: var(--text03);
  }

  &.inactive:hover > .cell {
    color: var(--text03);
  }

  &.done.inactive > .cell::after {
    border-bottom-color: var(--text03);
  }

  &.done.inactive:hover > .cell::after {
    border-bottom-color: var(--text03);
  }
}
