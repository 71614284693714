@use 'libs/assets/theme/variablesLight.scss';
@import 'libs/assets/theme/spacing.scss';

.login {
  text-align: center;
  color: #000;
  background-image: linear-gradient(0deg, #316997, transparent);
  background-color: var(--brand01);
  background-image: url('../../../../../libs/assets/img/bubble.png');
  background-repeat: no-repeat;
  background-size: cover;
  transition: background-color 0.3s linear;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 4rem;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -24.27%;
    height: 50%;
    background: var(--brand01);
    background: hsla(0, 0%, 100%, 0.08);
    background: linear-gradient(0deg, hsla(0, 0%, 100%, 0.39), var(--brand01));
    background: linear-gradient(
      0deg,
      hsla(0, 0%, 100%, 0.39),
      hsla(0, 0%, 100%, 0)
    );
    background: linear-gradient(
      0deg,
      hsla(0, 0%, 100%, 0.2),
      hsla(0, 0%, 100%, 0)
    );
    left: -30%;
    right: -30%;
    border-radius: 0 0 100% 100%;
  }

  &__top-block {
    position: relative;
    height: calc(25.73vh - 4.125rem);
    min-height: 4rem;
  }

  &__bubble-icon {
    height: 2.5rem;
  }

  &__title {
    font-size: 0.875rem;
    color: variablesLight.$textInverse;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }

  &__subtitle {
    color: variablesLight.$textInverse;
    margin-bottom: 1rem;
  }

  &__form {
    width: 100%;
    max-width: 21.4375rem;
    margin: 0 auto;
  }

  .form-input {
    width: 100%;
    margin-bottom: $spaceS;

    &__asterisk {
      color: #ec3737;
    }

    &__input-root {
      border-radius: 0.25rem;
      background-color: rgba(0, 0, 0, 0.15);

      &::before {
        display: none;
      }
    }

    &__input {
      color: variablesLight.$textInverse;
      color-scheme: dark;
    }

    &__label {
      font-size: 0.85rem;
      color: variablesLight.$textInverse;
    }
  }

  .autodiscovery-controller {
    padding: $spaceS;
    width: 100%;

    &__checkbox {
      color: variablesLight.$textInverse;

      &--checked {
        color: #ffbc50;
      }
    }

    &__label {
      color: variablesLight.$textInverse;
      text-transform: uppercase;
      font-size: 0.7rem;
      font-weight: 900;
    }
  }

  &__submit-btn {
    color: variablesLight.$text02;
    font-size: 0.875rem;
    font-weight: 900;
    padding: $spaceS 0;

    &:hover {
      background: #ffbc50;
    }

    background: #ffbc50;
    width: 100%;
  }

  &__submit-btn-label {
    height: $spaceS;
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
  }

  .clear-btn-wrapper {
    padding: 0;
  }

  .clear-btn {
    height: 3.5rem;
    width: 3.5rem;

    &__icon {
      color: variablesLight.$textInverse;
      width: $spaceS;
      height: $spaceM;
    }
  }

  &__demo-top-icon {
    color: variablesLight.$textInverse;
    width: 2.5rem;
  }

  &__demo {
    &-container {
      margin-top: $spaceL;
    }

    &-link {
      display: flex;
      justify-content: center;
      gap: $spaceS;
      color: variablesLight.$textInverse;
      text-decoration: none;
    }

    &-text {
      text-decoration: none;
      color: variablesLight.$textInverse;
    }
  }
}

.titleWrapper {
  height: 1.5rem;
  margin-top: $spaceXL;
  margin-bottom: $spaceS;
}

.circle {
  animation: circle 2s ease-in-out infinite;
}

@keyframes circle {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
