.overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(15px);
  top: 0;
  z-index: 500;
}

.container {
  margin-left: 1.75rem;
}

.item {
  background: rgba(255, 255, 255, 0.5);
  border: 3px dashed var(--text03);
  color: var(--text03);
  border-radius: 8px;
  width: 300px;
  height: 200px;

  &:hover {
    color: var(--brand01);
    border-color: var(--brand01);
    background: rgba(73, 156, 224, 0.25);
    .text {
      color: var(--brand01);
    }
  }
  &--active {
    color: var(--brand01);
    border-color: var(--brand01);
    background: rgba(73, 156, 224, 0.25);
    .text {
      color: var(--brand01);
    }
  }

  & * {
    pointer-events: none;
  }
}

.text {
  color: var(--text03);
}
