@use '@work4all/assets/theme/typography.scss';

.grid {
  @include typography.body1;

  display: grid;
  grid-template-columns: auto auto;
}

.name {
  color: var(--brand03);
}

.value {
  color: var(--text01);
}

.demoModeButton {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
