.metaData {
  display: flex;
}

.left {
  width: 100%;
}

.right {
  width: 50rem;
}

.xSplit {
  display: flex;
  gap: 1rem;
  & > * {
    width: 100%;
  }
}
