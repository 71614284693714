@use '@work4all/assets/theme/elevations.scss';

.dialog-paper-root {
  padding-top: var(--w4a-safe-area-top, 0px);
}

.popover-paper-root {
  display: flex;
  flex-direction: column;
  max-height: Min(650px, calc(100% - 32px));
  min-width: Min(360px, calc(100% - 32px));
  @include elevations.elevation6;
  border-radius: 0.5rem;
}
