.maskForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--ui03);
}

.tabsWrapper {
  flex: 1;
  display: grid;
  overflow: auto;
  height: 100%;
}
