.sorter {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  --iconColor: #{--brand01};
}

.icon {
  flex-shrink: 0;
  flex-grow: 0;
}
.content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 20px;
}
