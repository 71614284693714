.loader {
  align-self: center;
  justify-self: center;
}

.grid-div {
  display: inline-grid;

  .grid-entity-column {
    grid-column: 1 / 11;
  }

  .grid-file-column {
    grid-column: 11 / 12;
    margin: auto;
  }
}

.hide {
  display: none;
}

.ticket-status-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.hist {
  padding: 0 1rem;
  padding-bottom: 2rem;
}
