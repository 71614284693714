.root {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  flex: none;
  padding: 1rem;
  display: flex;
  align-items: center;
}

.buttons {
  display: flex;
  margin-left: auto;
  flex-direction: row;
}

.content {
  flex: 1;
  overflow: auto;
}
