@use '@work4all/assets/theme/breakpoints.scss';
@use '@work4all/assets/theme/mixins.scss';
@use '@work4all/assets/theme/elevations.scss';
@use '@work4all/assets/theme/typography.scss';

// Keep the list item height in sync with the normal picker.
$listItemHeight: 40px;

.root {
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.searchBar {
  padding: 0 1rem;
  border-bottom: none;
}

.row {
  @include typography.body1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  height: $listItemHeight;

  &:focus {
    background: var(--ui02);
  }
}

.process {
  flex: none;
  width: 16.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.startDate {
  flex: none;
  width: 5.5rem;
}

.highlight {
  background: var(--ui02);
  outline: none;
}
