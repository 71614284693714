@use '@work4all/assets/theme/breakpoints.scss';
@use '@work4all/assets/theme/typography.scss';
@use '@work4all/assets/theme/mixins.scss';

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  @media (min-width: breakpoints.$md) {
    flex-direction: row;
  }

  .legends {
    display: flex;
    padding: 1rem;
    gap: 1rem;

    .legend {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      &.approved {
        color: var(--success);
      }

      &.pending {
        color: var(--alert);
      }
    }
  }
}

.wrapper {
  display: grid;
  grid-template:
    'calendar' 1fr
    'footer' auto;
  height: 100%;
  position: relative;
}

.calendarWrapper {
  height: 100%;
  padding-bottom: 50px;

  &.mobile {
    overflow: scroll;
  }

  .autoSizerWrapper {
    height: 100%;
    overflow: auto;
  }
}

.resourceHeader {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.resource {
  padding: 0.5rem 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 0.5rem;
  height: 3rem;
  &:hover {
    background-color: #8585853f;
  }
}

.vacation {
  height: 1.5rem;
  margin: 0.25rem;
  width: calc(100% - 0.5rem);
  cursor: default;
  border-radius: 0.25rem;

  &.isMyVacation:hover {
    cursor: pointer;
    background: var(--brand01);

    &.isFirstHalfDay {
      margin: 0.25rem;
      width: 50%;
    }

    &.isSecondHalfDay {
      margin: 0.25rem;
      width: 50%;
      float: right;
    }
  }
}

.isPlaceholder {
  height: 100%;
  &:hover {
    cursor: pointer;
    background: var(--brand01);
  }
}

.smallSide {
  .calendar {
    :global {
      .mbsc-timeline-resource-col {
        width: 10rem;
      }
    }
  }
}

.calendar {
  --bg-holiday: repeating-linear-gradient(
    132deg,
    transparent,
    transparent 0.25rem,
    var(--ui02) 0.25rem,
    var(--ui02) 0.5rem
  );

  height: 100%;

  :global {
    .mbsc-timeline-resource-header {
      height: 100%;
    }
    .mbsc-timeline-events {
      bottom: 0;
    }

    .mbsc-schedule-event {
      bottom: 0;
    }

    .mbsc-timeline-resource-col {
      width: 21rem;
    }

    .mbsc-timeline-resource,
    .mbsc-timeline-row {
      flex: none;
      height: 2rem;
      min-height: auto !important;
      overflow: visible;
    }

    .mbsc-timeline-day,
    .mbsc-timeline-column {
      width: 2.5rem;
    }

    .mbsc-timeline-resource-title {
      height: 100%;
      padding: 0;
    }

    .mbsc-timeline-header-month {
      height: 2rem;
    }

    .mbsc-timeline-header-month-text {
      @include typography.caption;
      line-height: calc(2rem - 1px);
      padding-inline: 1rem;
      color: var(--text01);
    }

    .mbsc-timeline-header-date {
      height: 2.5rem;
    }

    .mbsc-timeline-header-date-text {
      font-family: 'Roboto', sans-serif;
      color: var(--text01);
      font-size: 0.85rem;
      line-height: 1rem;
      white-space: normal;
      text-align: center;
      padding: 0.25rem 0.5rem;

      &::first-line {
        font-size: 1rem;
        line-height: 1rem;
        font-weight: 700;
      }
    }

    .mbsc-schedule-event {
      height: 100% !important;
      top: 0 !important;
    }

    .mbsc-schedule-color {
      top: 0;
      bottom: 0;
      padding-top: 0;
      margin-top: 0;
    }

    .mbsc-timeline-row {
      border-bottom: 1px solid #ccc;
    }

    .mbsc-timeline-header-row-2 {
      height: 4.5rem;
    }

    .mbsc-timeline-event {
      padding: 0;
    }
  }
}

.grid {
  height: 100%;
  display: grid;
  grid-template-columns: 21rem auto 1fr;
  grid-template:
    'sidebar toolbar' auto
    'sidebar main' auto/260px 1fr;
}
