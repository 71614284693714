.preview-wrapper {
  padding-right: var(--w4a-safe-area-right, 0px);
  padding-left: var(--w4a-safe-area-left, 0px);
  padding-bottom: var(--w4a-safe-area-bottom, 0px);
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;

  :global .MuiDivider-root {
    border-color: var(--ui04);
  }
}
