.actionBox {
  border: 3px dashed var(--ui03);
  border-radius: 20px;
  padding: 2rem;
}

.canDrop {
  background-color: var(--ui08);
}

.dropzoneActive {
  &::after {
    content: '';
    position: absolute;
    z-index: 4;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(255 255 255 / 0.5);
    color: white;
    display: flex;
    align-items: center;
    justify-items: center;
    border: 3px dashed var(--ui04);
  }
}
