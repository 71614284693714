@use '@work4all/assets/theme/breakpoints.scss';

.root {
  border-left: 1px solid var(--ui03);
  width: 100%;
  flex: 1;

  &:not(.fullWidth) {
    max-width: 500px;

    & .container {
      max-width: 499px;
    }
  }

  &.fullWidth {
    border-left: 0;
  }
}

.root:not(.fullWidth) .container {
  &.fitContainer {
    position: absolute;
    padding-top: 3rem;
  }

  width: 100%;
  top: 0;
  bottom: 0;
  overflow: auto;
}

.header {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.headerButtonPadding {
  padding-bottom: 5rem;
}

.events {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editor {
  margin-bottom: 5rem;

  @media (max-width: breakpoints.$md) {
    margin-bottom: 0;
    position: fixed;
    bottom: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    padding-bottom: calc(10rem + var(--w4a-safe-area-bottom, 0px));
    background: var(--ui01);
    z-index: 9999;
    border-top: 1px solid var(--ui04);
  }

  :global(.fr-sticky-dummy) {
    display: none;
  }
}
