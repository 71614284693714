.overflow-list {
  display: flex;
  flex-wrap: nowrap;
  min-width: 0;
}

.overflow-list-spacer {
  flex-shrink: 1;
  width: 1px;
}
