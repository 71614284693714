@import 'libs/assets/theme/spacing.scss';

@import 'libs/assets/theme/mixins.scss';

.list-item {
  width: 100%;
  $self: &;

  &__id {
    min-height: 1rem;
  }

  &__item {
    padding: 0;
    padding-top: $spaceS;
    padding-bottom: $spaceXS;
    overflow: hidden;

    &--no-padding {
      padding: 0;
    }
  }

  &:first-child {
    #{$self}__item {
      padding-top: $spaceXXS;
    }
  }

  &__text {
    margin-top: 0;
    margin-bottom: 0;
    overflow: hidden;
  }

  &__title {
    display: block;
    color: #{--text01};
    @include ellipsis;
  }

  &__meta-wrapper {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.85rem;
    line-height: 1rem;
  }
}
