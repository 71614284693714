.list {
  order: -1;
  list-style: none;
  display: flex;
  gap: 0.5rem;
  padding: 0;
}

.visibleChip {
  visibility: visible !important;
}

.inActiveFilterChip {
  background-color: transparent;
  border: 1px solid var(--ui05);
  visibility: visible !important;
}
