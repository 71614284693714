.root {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  grid-gap: 1rem;
  align-items: center;
  width: max-content;

  padding: 1rem;
  box-sizing: border-box;

  .divider {
    &:after {
      width: 100%;
    }
  }

  .controlsGroup {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    .checkbox {
      padding: 0 0.5rem 0 0;
    }
  }

  .textField {
    display: inline-grid;
    grid-template-areas: 'title title' 'input adornment';
    grid-template-columns: 1fr max-content;

    background: var(--uiInput);
    border-radius: 0.25rem;

    padding: 0.3rem 0.5rem;
    box-sizing: border-box;

    .textField-title {
      grid-area: title;
      color: var(--text03);
    }

    .textField-input {
      grid-area: input;
      justify-self: end;

      width: 3rem;
      padding: 0 4px;
      box-sizing: border-box;

      border-radius: 0.25rem;
      transition: background 0.2s ease;

      &:hover {
        background: var(--ui04);
      }
    }

    .textField-adornment {
      grid-area: adornment;
      color: var(--text03);
    }

    .textAlign-end {
      text-align: end;
    }
  }
}
