.success {
  color: var(--success);
}

.alert {
  color: var(--alert);
}

.error {
  color: var(--error);
}

.default {
  color: var(---textInverse);
}
