.check-circle {
  color: var(--text03);
  font-size: 1.2rem;

  &.check-circle-success {
    color: var(--success);
  }

  &.check-circle-success {
    color: var(--success);
  }

  &.check-circle-edit {
    color: var(--alert);
  }

  &.disabled {
    color: var(--ui04);
  }
}
