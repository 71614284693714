@use '@work4all/assets/theme/breakpoints.scss';
@use '@work4all/assets/theme/typography.scss';
@use '@work4all/assets/theme/mixins.scss';

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-height: 0;
  overflow: hidden;
  background-color: var(--ui01);
}

.header {
  @include mixins.mainHeadDropshadow;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  padding-top: calc(1rem + var(--w4a-safe-area-top, 0px));

  .goBack {
    color: var(--text03);
    min-height: 3rem;
    width: 2.5rem;
    min-width: 2.5rem;
  }
}

.calendarWrapper {
  flex: 1;
}

.actionBar {
  display: flex;
  padding: 0.5rem 1rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;

  @media (min-width: breakpoints.$md) {
    flex-direction: row;
    align-items: center;
  }

  .right {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 1rem;
  }

  .rightMobile {
    display: grid;
    grid-template-rows: auto auto auto;
    gap: 1rem;
    width: 100%;
  }
}
