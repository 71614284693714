@use '@work4all/assets/theme/breakpoints.scss';

.metaData {
  display: flex;
  flex-direction: column;

  @media (min-width: breakpoints.$lg) {
    flex-direction: row;
  }
}

.left {
  width: 100%;
}

.right {
  width: 100%;
}

.xSplit {
  display: flex;
  gap: 1rem;
  flex-direction: column;

  & > * {
    width: 100%;
  }

  @media (min-width: breakpoints.$sm) {
    flex-direction: row;
  }
}

.xSplit > div {
  padding-bottom: 1rem !important;
}

.sectionPadding {
  padding: 1rem;

  @media (min-width: breakpoints.$lg) {
    padding: 1rem 3.5rem;
  }
}

.tabsSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;

  @media (min-width: breakpoints.$lg) {
    padding: 0 3.5rem 1rem;
  }
}

.tabsSectionDivider {
  width: 100%;
  margin-bottom: 0.75rem;
}

.subjectWrapper {
  padding-bottom: 0;
}
