@import 'libs/assets/theme/spacing.scss';
@import 'libs/assets/theme/elevations.scss';

.root {
  padding: 28px;
}

.bookingAssistance {
  @include elevation2;
  padding: $spaceS;
  border-radius: $spaceXS;
}

.bookingAssistanceTitle {
  margin-bottom: $spaceS;
}
