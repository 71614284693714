@use '@work4all/assets/theme/breakpoints.scss';
@import '@work4all/assets/theme/spacing.scss';

.root {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  // background-color:var(--textInverse);
}

.right {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
  isolation: isolate;
}

.rightRoot {
  display: flex;
  flex: 1 0 0;
  overflow: hidden;
}

.rightBody {
  height: 100%;
  flex: 1;
  display: grid;
}

.rightBody--split {
  grid-template-rows: 1fr auto;
}
.rightBody--horizontal-split {
  display: flex;
}

.tableWrapper {
  position: relative;
}

.table {
  box-shadow: 0px 4px 8px var(--elevationColor1);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

//hide and show states for filter buttons in column headers
.filterButtonClass {
  visibility: hidden;
  --iconColor: var(--ui05);

  &.activeFilter {
    --iconColor: var(--brand02);
    visibility: visible;

    &.readOnly {
      --iconColor: var(--brand01);
    }
  }
}

.headerClass:hover {
  .filterButtonClass {
    visibility: visible;
  }
}

.filter-wrapper {
  max-width: 25rem;
  min-width: 20rem;
  width: auto;
}

.filterHeadline {
  padding-left: $spaceS;
}

.progressBar {
  position: absolute;
  width: 100%;
  height: 3px;
}

.borderVertical {
  border-right: 1px solid var(--ui04);
}

.borderHorizontal {
  border-bottom: 1px solid var(--ui04);
}

.height100 {
  height: 100%;
}
