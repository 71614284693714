.placeholder {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.75rem 1rem;
  color: var(--text01);
  overflow-wrap: anywhere;
  display: flex;
  align-items: center;
  min-height: 3rem;

  & > .text {
    opacity: 0.5;
    font-style: italic;
  }

  & > .indicator {
    flex: 1;
    border-top: 1px solid var(--brand01);
  }
}
