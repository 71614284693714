.root {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--widgetBorder);
  position: relative;
  z-index: 1;
}

.input {
  flex: 1;
  border: none;
  outline: none;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5;
  padding: 1rem 0;
  color: var(--text01);
  background-color: transparent;
}

.input-disabled {
  color: var(--text03);
}

.icon-override {
  width: 1rem;
  height: 1rem;

  path {
    fill: var(--text03);
  }
}
