.menu {
  margin-top: 0.5rem;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.7rem 1rem;
  color: var(--text01);
}

.icon {
  color: var(--text03);
}
