.button {
  width: 100%;
  justify-content: flex-start;
  color: var(--brand01);

  &.disabled {
    color: var(--ui07);
    opacity: 0.26;
  }
}
