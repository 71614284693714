.dropdown {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  background-color: var(--ui01);
  border-radius: 0.5rem;
  box-shadow: 0 0.025rem 2rem var(--elevationColor1);
  width: 100%;

  &__input-stub {
    border-radius: 0.5rem;
  }

  &__input-stub {
    height: calc(var(--projectSearchBarHeight) + var(--w4a-safe-are-top, 0px));
  }

  &__list {
    padding: 0;
  }
}

.static {
  position: static;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
