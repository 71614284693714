@use '@work4all/assets/theme/elevations.scss';

.popover {
  @include elevations.elevation6;
}

.divider {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.paper {
  width: 10rem;
}

.button {
  padding: 0.5rem;
}

.primary {
  font-size: 1rem;
  line-height: 1.5;
}
