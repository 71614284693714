@use './../../../../../variables.scss';
@use '@work4all/assets/theme/breakpoints.scss';

.panel {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-right: var(--sectionHorizontalPadding);
  grid-template-areas: 'payment delivery';

  @media (max-width: breakpoints.$md) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'payment'
      'delivery';
    padding-right: 0;
  }
}
