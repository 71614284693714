.user-icon-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.transparentTable {
  *[role='row'] > div,
  *[role='row'],
  *[role='columnheader'] {
    background-color: transparent;
  }
}
