.container {
  position: relative;
  :global(.ptr__children) {
    transform: none !important;
  }
  :global(.ptr__pull-down) {
    position: fixed;
    z-index: 1000;
  }
}

.root {
  width: 100%;
  color: white;

  .top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      var(--brand01) 0%,
      rgba(73, 156, 224, 0) 100%
    );
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    // transition: opacity 0.5s;
  }
  .icon {
    position: absolute;
    top: calc(2rem + var(--w4a-safe-area-top, 0px));
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.5s;
  }

  .hidden {
    opacity: 0;
  }

  .rotate {
    animation: rotate 1s linear infinite;
  }

  @keyframes rotate {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
}
