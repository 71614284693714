@use 'libs/assets/theme/variables.scss';

.favActive {
  color: var(--brand02);
}

.description {
  max-width: 60vw;
  text-overflow: ellipsis;
  overflow: hidden;
}
