@use 'libs/assets/theme/spacing.scss';

.rootPopover {
  pointer-events: none;
}

.paperPopover {
  width: 416px;
  background-color: var(--ui01);
  border: 1px solid var(--ui02);
  box-shadow: 0px 6px 12px var(--elevationColor3);
  border-radius: 8px;
  padding: 8px 18px;
}

.titleTimeAndIconContainer {
  display: flex;
  justify-content: space-between;
}

.text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding-right: 16px;
}

.businessPartnerAndProjectContainer {
  margin-top: 8px;
}

.businessPartnerAndProjectContainer > :not(:last-child):not(:only-child) {
  margin-bottom: 4px;
}

.businessPartnerAndProjectPrefix {
  color: var(--text03);
  display: inline-block;
  width: 75px;
}

.usersAndResourcesContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  align-items: center;
}

.usersContainer {
  display: flex;
}

.usersContainer > :not(:last-child):not(:only-child) {
  margin-right: 4px;
}

.numberOfOtherUsersContainer {
  background-color: var(--ui05);
  height: var(--spaceL);
  width: var(--spaceL);
}

.resourcesContainer > :not(:last-child):not(:only-child) {
  margin-right: 4px;
}

.resourceChip {
  max-width: 100px;
}

.previewTextContent {
  padding: 0;
}

.note {
  margin-top: 16px;
}

.noteLine {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  &.moreLines {
    -webkit-line-clamp: 3;
  }
}
