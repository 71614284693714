.vendorAndCurrency {
  grid-area: vendor-and-currency;
}

.exchangeDisplay {
  padding-top: 1rem;
  span {
    color: var(--text03);
    padding-left: 0.25rem;
  }
}
