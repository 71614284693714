@use 'sass:math';

@import 'libs/assets/theme/elevations.scss';
@import 'libs/assets/theme/spacing.scss';

.image-gallery {
  display: grid;
  grid-template-rows: 1fr auto auto;
  grid-row-gap: $spaceL;
}

.img-wrap {
  position: relative;
  overflow-y: auto;
  text-align: center;
  line-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    height: 100%;
    width: 100%;
  }

  .img-prev {
    position: absolute;
    top: 0%;
    left: 0;
    bottom: 0;
  }

  .img-next {
    position: absolute;
    top: 0%;
    right: 0;
    bottom: 0;
  }

  .img-prev,
  .img-next {
    display: none;
    color: var(--textInverse);

    svg {
      width: 100px;
      height: 100px;
      -webkit-filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.7));
      filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.7));
    }
  }

  &:hover {
    .img-prev,
    .img-next {
      display: block;
    }
  }
}

.img-holder {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.img-infos {
  text-align: center;
}

.file-size {
  color: var(--text03);

  .separator {
    padding: 0 8px;
  }
}

.thumbs-container {
  text-align: center;
  overflow-x: auto;

  .thumbs-wrap {
    display: inline-block;
    padding: $spaceL;
    padding-top: $spaceS;

    .thumbs {
      display: flex;
      flex-direction: row;
      gap: $spaceL;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;

      .thumb {
        vertical-align: middle;
        text-align: center;
        width: $spaceXXL;
        height: $spaceXXL;
        background: center center;
        background-size: cover;
        cursor: pointer;
        border-radius: var(--borderRadius);
        @include elevation3;

        &.selected {
          box-sizing: content-box;
          border: 8px solid var(--brand01);
        }
      }
    }
  }
}
