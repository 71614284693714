.attachmentCollapse {
  width: 100%;
}

.attachments {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.attachmentCollapseHeaderBar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.attachmentActionButtonWrapper {
  position: relative;
  display: inline-block;
}

.attachmentTitleWrapper {
  display: flex;
  column-gap: 0.3rem;
  .attachmentTitleTranslation {
    color: var(--text01);
  }
  .attachmentTitleAmount {
    color: var(--text03);
  }
}

.attachmentTable {
  position: absolute;
  width: 100%;
  border-top: 0.0625rem solid var(--ui04);
}

.attachmentTableCompact {
  max-height: 7.5rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.tableWrapper {
  width: 100%;
  display: flex;
  flex: 1;
  position: relative;
}

.tableContent {
  flex: 1;
}

.actionBarWrapper {
  flex: none;
}

.previewWrapper {
  border-left: 1px solid var(--ui04);
  border-top: 1px solid var(--ui04);
  background-color: var(--ui02);
  justify-self: right;
}

.dropArea {
  position: relative;

  &.fullHeight {
    height: 100%;
  }
}

.dropzoneActive {
  &::after {
    content: '';
    position: absolute;
    z-index: 4;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(255 255 255 / 0.5);
    color: white;
    display: flex;
    align-items: center;
    justify-items: center;
    border: 3px dashed var(--ui04);
  }
}

.fullHeight::after {
  position: fixed;
  z-index: 9999999;
}

.dropzoneText {
  color: white;
  display: inline-block;
}

.progressbarContainer {
  position: relative;
  height: 0.25rem;
}

.progressbar {
  position: absolute;
  width: 100%;
  z-index: 99;
}

.compactViewWrap {
  .attachmentBadgeWrap {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
  }

  .attachmentBadge {
    display: flex;
    align-items: center;
    border: 1px solid var(--ui04);
    border-radius: 0.5rem;
    cursor: pointer;
    overflow: hidden;

    .content {
      display: flex;
      padding: 0.5rem;
      align-items: center;
      gap: 0.5rem;
      height: 100%;

      &:hover {
        background: var(--ui03);
      }
      .text {
        max-width: 10rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .dividerVert {
      background: var(--ui04);
      width: 1px;
      height: 100%;
    }

    .attButton {
      width: 2rem;
      &:hover {
        background: var(--ui03);
      }

      .chevronDown {
        flex: none;
        width: 1.5rem;
        height: 1.5rem;
        color: var(--text01);
      }
    }
  }

  .moreLink {
    margin-top: 8px;
  }
}

.itemText {
  display: flex;
  width: 100%;
}

.listItem {
  cursor: pointer;
  &:hover {
    background: var(--ui03);
  }
}

.disabled {
  color: var(--text03);
}
