@import 'libs/assets/theme/spacing.scss';
@import 'libs/assets/theme/mixins.scss';

.wrapper {
  background-color: var(--ui02);
  border: 1px solid var(--ui04);
  border-radius: $spaceXXS;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-height: var(--labeledInputHeight);
  position: relative;

  &.underline {
    background: transparent;
    border-bottom: 1px solid var(--ui04);
    border-radius: 0;
  }

  &.focused {
    border: 1px solid var(--brand01);
    background-color: var(--ui01);
  }

  @include errorStyle;
}

.left {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  padding: $spaceXXS $spaceXS;
}

.label {
  top: 0;
  left: $spaceXS;
  font-family: inherit;
  transform: translateY(100%);
  cursor: inherit;
  max-width: calc(100% - 2 * 0.5rem);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1;

  &--shrink {
    color: var(--text02) !important;
    top: $spaceXXS;
    transform: initial;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    z-index: 1; //give it a higher zindex then the sibligs elswise the browsers autofill styles may hide the label
  }
}

.text02 {
  color: var(
    --text02
  ) !important; // want to override all MUI states (for example, "focused")
}

.text03 {
  color: var(--text03) !important;
}

.inputMargin {
  margin-top: 0 !important; // MUI style for this element is more specific. want to override it
}

.inputWrapper {
  padding: 0;
  cursor: inherit !important; // MUI style for this element is more specific. want to override it
  width: 100%;
  flex: 1;

  &::before {
    content: unset;
  }

  &::after {
    content: unset;
  }
}

.fontStyles {
  input {
    font-family: inherit;
    cursor: inherit;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.noMargin {
  margin-top: 0 !important;

  .input {
    padding-top: 0;
  }
}

.input {
  min-width: 0;
  padding: 0;
  padding-top: 1rem;
  @include ellipsis;

  &[type='number'] {
    text-align: right;
  }
}

.multilineWithDynamicHeight {
  outline: 0;
  padding-top: 1rem;
  height: 100%;
  overflow-y: auto;
}

.multiline {
  min-height: 100% !important; // MUI style for this element is more specific. want to override it
  overflow-y: auto !important; // MUI style for this element is more specific. want to override it
  resize: vertical;
}

.actionsWrapper {
  display: none;
  padding: $spaceXS 0 0;
}

:hover > .actionsWrapper {
  display: flex;
  padding: $spaceXS $spaceXS 0;
  max-height: 2.5rem;
}

.actionBtn {
  display: flex;
  align-items: center;
  margin-right: 0.625rem;
  cursor: pointer;

  &:last-child {
    margin-right: 0.3125rem;
  }
}

.close {
  color: #a1a4b1;
}

.inputField::placeholder {
  opacity: 1;
  color: var(--text03) !important;
}
