@import 'libs/assets/theme/elevations.scss';
@import 'libs/assets/theme/spacing.scss';

.time-preview {
  display: flex;
  overflow: auto;
  z-index: 10;

  &__resizable_area {
    display: flex;
    > div:first-of-type {
      display: flex;
    }
  }
  &__root {
    flex: 1 0;
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--ui04);
  }
  &__content {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__row {
    display: flex;
    width: 100%;
  }

  &__item {
    flex: 1;
    align-items: center;
  }
  &__icon {
    padding: 0.75rem 0.875rem 0.75rem 0;
  }

  &__separator {
    border-bottom: 0.0625rem solidvar(--ui03);
    margin: 1rem 0;
  }

  &__buttons {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 1rem 0;
  }

  &__tooltip {
    font-size: 1rem;
    padding: 0.375rem 0.5rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: var(--textInverse);
    &-constraint {
      width: 1px;
      height: 1px;
    }
  }
}

.double-time-entry {
  display: flex;
  &__item {
    flex: 1;
  }

  &--left {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  &--right {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 0.0625rem solid var(--ui04);
  }
}
