.timeFrameSwitchAndAgendaSizeContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;
  width: 100%;
}

.shrinker {
  position: absolute;
  top: 9rem;
  right: 0;
  z-index: 100;

  &.expanded {
    top: 24rem;
  }
}
