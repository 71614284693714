@use '@work4all/assets/theme/breakpoints.scss';

.root {
  margin: 1rem 3.5rem;

  @media (max-width: breakpoints.$md) {
    margin: 1rem 1rem;
    margin-bottom: 6rem;
  }
}
