.root {
  position: relative;
  overflow: auto;
}

.row {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
