.control {
  background: var(--ui01);
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.maxHeight {
  height: 100%;
}
