.actionButton {
  flex-grow: 1;
}

.dialogTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dialogContent {
  margin-top: '8px';
}

.table {
  height: 200px;
  overflow: scroll;
}
