.avatarWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0;
  transition: filter 0.2s ease;

  &:hover {
    filter: grayscale(40%);

    .settingsIcon {
      opacity: 1;
    }
  }
}

.settingsIcon {
  position: absolute;
  opacity: 0;
  transition: opacity 0.2s ease;
  color: var(--text01);
}
