@import 'libs/assets/theme/variablesLight.scss';

.wrapper {
  display: flex;
  padding: 16px;
  border-radius: 8px;
  background: linear-gradient(27deg, #ffe297 0%, #ffc2c2 100%);
  box-shadow: 0px 0px 2px 0px var(--elevationColor2);
  color: $text01;
}

.textWrapper {
  margin-left: 16px;
}

.textWrapper > :not(:last-child) {
  margin-bottom: 8px;
}

.cta {
  color: $brand01;
}
