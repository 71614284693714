@use '@work4all/assets/theme/breakpoints.scss';
@use '@work4all/assets/theme/mixins.scss';
@use '@work4all/assets/theme/elevations.scss';
@use '@work4all/assets/theme/typography.scss';

.root {
  flex: 1;
  position: relative;
}

.grid {
  height: 100%;
  display: grid;
  grid-template:
    'toolbar' auto
    'alert' auto
    'main' 1fr
    'footer' auto;

  &:not(.forceMobileView) {
    @media (min-width: breakpoints.$md) {
      grid-template:
        'sidebar toolbar' auto
        'sidebar alert' auto
        'sidebar main' 1fr
        'footer footer' auto / 260px 1fr;
    }

    &.showSidebar {
      grid-template:
        'sidebar toolbar' auto
        'sidebar alert' auto
        'sidebar main' 1fr
        'footer footer' auto / 260px 1fr;
    }
  }
}

:global {
  .mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-week-day,
  .mbsc-font {
    font-size: 1rem;
  }
}
