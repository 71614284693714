.wrapper {
  display: grid;
  grid-template-columns: 2.5rem auto;
}

.prefix {
  display: flex;
  background-color: var(--gray06);
  border-radius: 4px 0px 0px 4px;
  justify-content: center;
  align-items: center;
}

.picker {
  background-color: var(--gray06);
  border: none;
  border-radius: 0px 4px 4px 0px;
}
