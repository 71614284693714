@use '@work4all/assets/theme/elevations.scss';

.popover {
  @include elevations.elevation6;
}

.divider {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.paper {
  width: 100%;
  max-width: 16rem;
}

.listItemButton {
  min-width: 0px;
  padding: 0.5rem;
}
