@use '@work4all/assets/theme/breakpoints.scss';

.emailKindCellRoot {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 1.5rem;
  gap: 1rem;

  svg {
    color: var(--text03);
  }
}

.label {
  display: none;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: breakpoints.$lg) {
    display: block;
  }
}

.icon {
  @media (min-width: breakpoints.$sm) {
    display: none;
  }
}
