@use 'libs/assets/theme/spacing.scss';

.grid {
  gap: spacing.$spaceXS spacing.$spaceS;
  display: grid;
  grid-template-columns: auto auto 1fr;
  padding: spacing.$spaceS;
}

.span2 {
  grid-column: 1 / 3;
}

.label {
  color: var(--text03);
  padding-right: 2rem;
}

.noteContainer {
  padding: 1rem;
}
