.panel {
  display: flex;
}

.mobile {
  flex-direction: column;
}

.divider {
  background-color: var(--ui03);
}
