.linkPreview {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1;
  text-transform: uppercase;
  color: var(--textInverse);
  background: #499ce0;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
}
