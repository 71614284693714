@import 'libs/assets/theme/breakpoints.scss';

.row {
  height: 2rem !important;
  align-items: center;
  background: var(--ui02);
  border-bottom: 1px solid var(--ui04);
}

.label {
  flex: none;
  position: sticky;
  left: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.line {
  margin-right: 1rem;
  flex: 1;
  border-bottom: 1px solid var(--ui04);
}
