.checkboxWrap {
  display: flex;
  width: 100%;

  .settingsButton {
    visibility: hidden;
  }

  &:hover .settingsButton {
    visibility: visible;
  }
}

.checkbox {
  padding: 0.5rem 0;
}
