.panel {
  display: flex;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}
