.previewWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.previewFullscreen {
  background-color: var(--ui03);
  height: 100%;
}

.actionBar {
  display: flex;
  flex-direction: row;
  flex-flow: row-reverse;
}

.previewContainer {
  height: 100%;
}
