@use '@work4all/assets/theme/breakpoints.scss';

.panel {
  display: grid;
  grid-template-columns: 6fr 6fr;
  grid-template-rows: auto;
  padding-right: var(--sectionHorizontalPadding);
  grid-template-areas:
    'general receipt'
    'others receipt'
    'costs receipt'
    'empty receipt'
    'empty receipt';
  height: 100%;

  @media (max-width: breakpoints.$md) {
    display: flex;
    flex-direction: column;
    padding-right: 0;
  }
}

.receipt {
  background-color: var(--ui03);
  grid-area: receipt;
  flex-direction: column;
  display: flex;
  &-root {
    flex: 1;
  }
  &-wrapper {
    height: 100%;
  }
}

.left {
  grid-area: general;
}
