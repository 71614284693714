@use '@work4all/assets/theme/typography.scss';

.scheduler {
  flex: 1;
  overflow: auto;
}

.footer {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.timelineContainer {
  height: 100%;
  overflow: hidden;
}

.listContainer {
  border-top: 1px solid var(--ui04);
  border-bottom: 1px solid var(--ui04);
  height: 100%;
  overflow-y: auto;
}

.listEvent {
  border-bottom: 1px solid var(--ui04);
  cursor: pointer;

  display: grid;
  grid-template-columns: 5rem 1fr;

  &.suggestion {
    background: repeating-linear-gradient(
      132deg,
      transparent,
      transparent 0.25rem,
      var(--ui02) 0.25rem,
      var(--ui02) 0.5rem
    );
  }

  &:hover {
    background-color: var(--ui02);
  }

  .eventTitle {
    padding-bottom: 0.5rem;
    font-weight: 600;
  }

  .eventDetails {
    @include typography.caption;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0.5rem;
  }

  .detailsLabel {
    color: var(--text03);
    width: 60px;
  }

  .detailsValue {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .timeContainer {
    border-right: 1px solid var(--ui04);
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .eventContent {
    padding: 0.5rem;
  }

  .timeSpanArrow {
    color: var(--text03);
  }

  .timeSpanAdd {
    color: var(--brand01);
  }
}

.equalFlexWith {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
}

.projectHours {
  height: 100%;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;

  :global {
    .mbsc-schedule-header {
      display: none;
    }

    .mbsc-schedule-event {
      padding: 1px 2px !important;
    }

    .mbsc-schedule-all-day-wrapper {
      max-height: unset;
      border-bottom: 2px solid var(--ui04);
      min-height: calc(2rem + 4px);
    }

    .mbsc-schedule-event-all-day {
      box-sizing: border-box;
      margin: 0;
    }

    .mbsc-schedule-all-day-text {
      display: none;
    }

    .mbsc-schedule-item {
      border-top: none;
      height: 10rem;
    }

    .mbsc-schedule-time-wrapper {
      height: 10rem;
    }

    .mbsc-schedule-time-col {
      border-right: 1px solid var(--ui04);
    }

    .mbsc-schedule-time-indicator-time {
      @include typography.caption;
      color: var(--brand01);
      top: -0.5em;
      padding-right: 16px;
      background: var(--ui01);
      background-clip: content-box;
    }

    .mbsc-schedule-time {
      @include typography.caption;
      color: var(--text03);
      top: -0.5em;
      padding-right: 16px;

      &::after {
        content: '';
        width: 8px;
        display: block;
        border-bottom: 1px solid var(--ui04);
        position: absolute;
        right: 0;
        top: 0.5em;
      }
    }

    .mbsc-schedule-time-indicator-cont {
      border: none;
    }

    .mbsc-schedule-time-end {
      top: unset;
      bottom: -0.5em;
    }

    .mbsc-schedule-time-indicator {
      left: -8px !important;
      width: calc(100% + 8px) !important;
      border-bottom: 1px solid var(--ui04);
    }

    .mbsc-schedule-wrapper {
      border-top: 1px solid var(--ui04);
      border-bottom: 1px solid var(--ui04);
    }

    .mbsc-schedule-item-last {
      border-bottom: none;
    }

    .mbsc-schedule-all-day-wrapper-shadow::after {
      display: none;
    }
  }
}

.fab {
  position: absolute;
  bottom: 6rem;
  right: 2rem;
  z-index: 10;
}

.event {
  padding: 0.25rem 0.5rem;
  background-color: #c6e5ff;
  border-radius: 4px;
  height: 100%;
  overflow: hidden;

  .header {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }

  .info {
    margin-top: 0.25rem;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0 1rem;

    .label {
      color: var(--text03);
    }

    .value {
      color: var(--text02);
    }
  }
  :global(.mbsc-schedule-event-hover) & {
    background-color: #b1cfe8;
  }

  :global(.mbsc-schedule-event-end:not(.mbsc-schedule-event-start)) & {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 2px dashed var(--brand01);
  }

  :global(.mbsc-schedule-event-start:not(.mbsc-schedule-event-end)) & {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 2px dashed var(--brand01);
  }
}

.dialogWrapper {
  max-width: 600px;
}
