.list-item-content {
  width: 100%;
  display: flex;
  gap: 1rem;
}

.name {
  flex: 0 0 50%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.honorific {
  flex: 0 0 2ch;
  color: var(--text03);
}

.role {
  flex: 1 1 0;
  color: var(--text03);
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.primary-contact-icon {
  flex: none;
  color: var(--text03);
}
