@import 'libs/assets/theme/spacing.scss';

.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.searchRoot {
  height: 100%;
}

.searchBar {
  min-height: var(--projectSearchBarHeight);
  height: 100%;
  align-items: flex-start;
  padding: $spaceS;
}

.searchIconWrapper {
  width: auto;
  margin-right: $spaceS;
  height: $spaceS;
}
