/* slideTransition.scss */

/**
 * Stack item is either "pushed" or "popped"
 * data-direction attr store current state
   PUSH: transition new element from left to right
   POP: transition old element from right to left
*/

.page {
  height: 100%;
  width: 100%;
}

:global {
  .direction,
  .transtion-group {
    height: 100%;
    transform: translateZ(0);
  }

  [data-direction='push'] {
    & > .push-enter,
    & > .pop-enter {
      z-index: 100;
      transform: translateX(100%);
    }
  }

  [data-active-direction='push'] {
    & > .push-enter-active,
    & > .pop-enter-active {
      transition: transform #{var(--slideTransitionTimeout)}ms
        cubic-bezier(0, 1, 0, 1);
      transform: translateX(0);
    }
  }

  [data-direction='pop'] {
    & > .push-exit,
    & > .pop-exit {
      z-index: 100;
      transform: translateX(0);
    }
  }

  [data-active-direction='pop'] {
    & > .push-exit-active,
    & > .pop-exit-active {
      transition: all #{var(--slideTransitionTimeout)}ms
        cubic-bezier(0, 1, 0, 1);
      transform: translateX(100%);
    }
  }
}
