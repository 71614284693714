.safe-area-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--w4a-safe-area-top);
  background: rgba(255, 0, 0, 0.5); /* Red overlay for visibility */
  z-index: 9999; /* Ensure it is on top of other content */
}

.safe-area-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: var(--w4a-safe-area-bottom);
  background: rgba(0, 0, 255, 0.5); /* Blue overlay for visibility */
  z-index: 9999; /* Ensure it is on top of other content */
}
