.permit {
  grid-area: permit;
}

.input {
  flex: 1;
  min-width: 140px;
}

.button {
  flex: none;
}

.locked {
  svg {
    color: var(--brand02);
  }
  color: var(--text03);
}

.approved {
  svg {
    color: var(--brand01);
  }
  color: var(--text03);
}
