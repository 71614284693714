@import '@work4all/assets/theme/spacing.scss';

.content {
  background-color: var(--ui03);
  height: 100%;
  overflow-y: auto;
}

.secondaryFiltersWrap {
  padding: 0 $spaceS;
}

.filterNavLabel {
  flex-grow: 1;
}

.itemText {
  display: flex;
  width: 100%;
}

.filterValue {
  color: var(--text03);
}

.wrapper {
  max-width: 30rem;
}
