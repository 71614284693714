.check-list {
  &__header {
    padding: 0.5rem 1rem 0.5rem;
  }

  &__label {
    flex: 1;
  }

  &__pointer {
    cursor: pointer;
  }

  &__item {
    display: flex;
    align-items: center;
    padding-right: 1rem;

    .side-element {
      opacity: 0;
    }
  }

  &__row {
    padding-left: 1rem;
    height: 2.25rem;
    gap: 0.5rem;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    &:hover .side-element {
      opacity: 1;
    }

    &.checked {
      background: rgba(73, 156, 224, 0.14);
    }
  }

  &__container {
    overflow-y: scroll;
  }

  &__container::-webkit-scrollbar {
    width: 4px;
  }

  &__container::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.38);
    border-radius: 16px;
  }

  &__container::-webkit-scrollbar-track {
    background-color: #fff;
  }
}
