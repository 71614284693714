@use '@work4all/assets/theme/breakpoints.scss';
@import 'libs/assets/theme/spacing.scss';

$sectionHorizontalPadding: $spaceXS + $spaceM + $spaceM;
.topIndent {
  padding-top: $spaceS;
}

.rightIndent {
  padding-right: $sectionHorizontalPadding;
}

.bottomIndent {
  &--sm {
    margin-bottom: $spaceXS;
  }

  &--md {
    margin-bottom: $spaceXS;
  }

  &--lg {
    margin-bottom: $spaceXS;
  }
}

.grid {
  display: grid;
}

.headerWrapper {
  display: flex;
  padding-inline: 1rem;

  @media (min-width: breakpoints.$lg) {
    padding-left: 1.5rem;
    padding-right: 3.5rem;
  }
}

.button,
.iconButton {
  flex: none;
}

.button {
  .icon {
    display: none;
    margin-right: 0.5rem;

    @media (min-width: breakpoints.$lg) {
      display: block;
    }
  }
}

.iconButton {
  @media (min-width: breakpoints.$lg) {
    display: none;
  }
}

.headerContent {
  flex: 1;
}

.header {
  padding-left: $spaceM;
  padding-right: $spaceXS;
  display: flex;
  justify-content: flex-start;
  min-width: 48px;

  &--withoutToggle {
    padding-left: $sectionHorizontalPadding;
  }
}

.icon {
  color: #8b90a0;
  transition: transform 0.1s;
}

.iconCollapsed {
  transform: rotate(-90deg);
}

.heading {
  text-transform: unset;
  font-size: $spaceS;
  line-height: unset;
  height: $spaceXL;
}

.noButton {
  @media (min-width: breakpoints.$lg) {
    margin-left: 2rem;
  }
}

.collapseContainer {
  padding-inline: 1rem;

  @media (min-width: breakpoints.$lg) {
    padding-inline: 3.5rem;
  }
}

.withPaddingRight {
  padding-right: $sectionHorizontalPadding;
}
