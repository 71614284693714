.inputWrapper {
  :global {
    .fr-wrapper {
      .fr-iframe {
        min-height: 2rem !important;
      }
    }

    .fr-sticky-dummy {
      background-color: transparent !important;
    }

    .fr-view {
      padding-left: 0px !important;
    }

    .fr-box {
      border: 0;
    }

    .fr-wrapper {
      padding: 0;
    }
  }
}

.inputWrapper {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  margin-bottom: -5px;
}
