@import '@work4all/assets/theme/variables.scss';
@import '@work4all/assets/theme/typography.scss';

.backgroundIcon {
  position: absolute;
  color: var(--ui03);
  height: 20rem;
  width: 20rem;
  right: -5rem;
  top: -5rem;
}

.bzObjTypeHeading {
  font-size: 1.75rem;
  color: var(--text01);
}
