@import 'libs/assets/theme/spacing.scss';
@import 'libs/assets/theme/elevations.scss';

.bar {
  display: flex;
  justify-content: space-between;
}

.takeAvailable {
  flex: 1;
}

.contextMenuButton {
  padding: 0 $spaceS;
  color: var(--brand01);
}

.expandButton {
  color: var(--brand01);
}

// TODO: set elevation to all popovers?
.contextMenuPopover {
  @include elevation2;
}

.left,
.right {
  display: flex;
  align-items: center;
}

.left {
  justify-content: flex-start;
}

.right {
  justify-content: flex-end;
}

.marging--xss {
  margin-right: $spaceXXS;
}

.margin--xs {
  margin-right: $spaceXS;
}

.margin--lg {
  margin-right: $spaceL;
}

.wrapper {
  display: flex;
  align-items: center;
  &:last-child {
    margin-right: 0;
  }
}
