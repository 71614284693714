.previewContent {
  height: 100%;
  min-height: 600px;
  border-radius: 4px;
  background: var(--gray06);
}

.collapse {
  flex: 1;
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;
  overflow: hidden;
  min-height: 30rem;
}

.collapseContainer {
  display: grid;
}

.collapseWrapperContent {
  background-color: var(--uiInput);
  width: 100%;
  overflow: hidden;
}
