@import 'libs/assets/theme/typography.scss';

.wrap {
  display: flex;
  align-items: center;
  gap: $spaceXS;
  padding-top: $spaceS;
}

.caption {
  @include caption;
}

.body {
  @include body1;
}

.title {
  color: var(--text03);
}

.line {
  height: 1px;
  background-color: var(--ui04);
  flex: 1;
}

.horizontalPadding {
  padding-left: 1rem;
  padding-right: 1rem;
}

.vertical {
  height: 100%;
  width: 1px;
  .line {
    height: 100%;
  }
}
