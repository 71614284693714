@import 'libs/assets/theme/spacing.scss';
@import 'libs/assets/theme/typography.scss';
@import 'libs/assets/theme/breakpoints.scss';

.dashboardWrapper {
  background-color: var(--ui03);
  height: 100%;
  padding: $spaceS;
  gap: 1rem;
  overflow: auto;
}

.dashboardRow {
  display: flex;
  align-items: flex-start;
  gap: $spaceS;

  & > .dashboardItem {
    width: 25%;
    height: 100%;
  }
}

.dashboardValues {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboardPreviousValueWrap {
  display: flex;
  flex-direction: column;
}

.dashboardTrend,
.dashboardTrendIndicator {
  @include caption;

  &.dashboardTrendEven {
    color: var(--text03);
  }

  &.dashboardTrendSlightlyUp,
  &.dashboardTrendUp {
    color: var(--success);
  }

  &.dashboardTrendSlightlyDown,
  &.dashboardTrendDown {
    color: var(--error);
  }
}

.dashboardTrendIndicator {
  height: 2.5rem;
  width: 2.5rem;

  &.dashboardTrendEven {
    transform: rotate(90deg);
  }

  &.dashboardTrendSlightlyUp {
    transform: rotate(45deg);
  }

  &.dashboardTrendSlightlyDown {
    transform: rotate(135deg);
  }
  &.dashboardTrendDown {
    transform: rotate(180deg);
  }
}

.value {
  @include heading1;
  font-size: 1.75rem;
}

.alternateRow {
  background: var(--ui03);
}
