@import '../../../../../../../variables.scss';

.common {
  flex: 1;
  display: auto;
  flex-direction: column;
}

.mobile {
  order: 1;
}

.collapse {
  flex: 1;
  display: grid;
  grid-template-rows: auto 1fr;
}

.collapseContainer {
  display: grid;
}

.collapseWrapperInner {
  display: grid;
  grid-template-rows: auto 1fr;
}

.controlWrapper {
  flex: 1;
}

.infoIcon {
  color: var(--brand01);
  margin-left: 0.5rem;
  margin-top: 0.75rem;
  width: 1rem;
  height: 1rem;
  align-self: center;
}
