@import 'libs/assets/theme/elevations.scss';
@import 'libs/assets/theme/spacing.scss';

.details-header {
  padding-left: 0.25rem;
  padding-right: 0.25rem;

  &__root {
    position: sticky;
    top: 0;
    background: var(--ui01);
    z-index: 2;
    padding-top: var(--w4a-safe-area-top, 0px);
  }

  &__body {
    color: var(--text03);
    padding-right: 0.5rem;
  }

  svg:hover {
    cursor: pointer;
  }

  path {
    fill: var(--text01);
  }
}

h4.details-header {
  &__title {
    color: var(--text01);
    padding-left: 0.25rem;
    margin-top: 0.5rem;
  }
}
