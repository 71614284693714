.no-scroll {
  overflow: auto;
}

.table {
  flex: 1;
}

.collapse {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.collapse-indent {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.collapse-wrapper {
  flex: 1;
}

.collapse-wrapper-inner {
  display: flex;
  flex-direction: column;
}

.mask-tab-panel-override {
  overflow-y: hidden;
}
