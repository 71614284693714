@use '@work4all/assets/theme/elevations.scss';

.popoverRoot {
  @include elevations.elevation6;
  border-radius: 0.5rem;
}

.popoverFolderList {
  padding: 0;
  width: 18rem;
}

.popoverItem {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.75rem 1rem;
  color: var(--text01);
  overflow-wrap: anywhere;

  &.active {
    color: var(--brand01);
    font-weight: bold;
  }
}

.popoverFolderItem {
  justify-content: space-between;
}

.folderIcon {
  flex: none;
  width: 24px;
  height: 24px;
}

.popoverFolderItemInput {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.75rem 1rem;
  color: var(--text01);
  min-width: 0;
  width: 100%;
  border: none;
  outline: none;
  background: rgba(0, 0, 0, 0.05);
}

.popoverLinkItemOver {
  margin-bottom: 9px;
}
