@use '@work4all/assets/theme/breakpoints.scss';

.grid {
  position: relative;
  padding: 1rem;

  padding-right: var(--w4a-safe-area-right, 0px);
  padding-left: var(--w4a-safe-area-left, 0px);

  @media (min-width: breakpoints.$md) {
    display: grid;
    grid-template-columns: minmax(10rem, 16rem) 1fr;
    grid-template-areas: 'outline content';
    gap: 2rem;
    padding: 1rem;
    padding-right: var(--w4a-safe-area-right, 0px);
    padding-left: var(--w4a-safe-area-left, 0px);
  }

  @media (min-width: breakpoints.$lg) {
    grid-template-columns: minmax(10rem, 24rem) 1fr minmax(8rem, 24rem);
    grid-template-areas: 'outline content info';
  }
}

.stickyTop {
  position: sticky;
  top: 11rem;

  @media (min-width: breakpoints.$lg) {
    top: 6rem + var(--headerHeight);
  }
}

.mobileDivider {
  margin: 0 0.5rem;
}

.outlineWrapper {
  grid-area: outline;

  display: none;

  @media (min-width: breakpoints.$md) {
    display: block;
  }
}

.groupsWrapper {
  grid-area: content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem;
}

.infoWrapper {
  grid-area: info;
  display: none;

  @media (min-width: breakpoints.$lg) {
    display: block;
  }
}

.info {
  color: var(--text01);
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &.dark {
    background: rgba(0, 0, 0, 0.11);
    backdrop-filter: blur(27px);
    border-radius: 0.5rem;
    color: var(--textInverse);
  }

  .howItsDone {
    display: flex;
    align-items: center;

    & > svg {
      margin-right: 0.5rem;
    }
  }

  h4 + p {
    margin-top: 1rem;
  }

  p + p {
    margin-top: 0.5rem;
  }
}

.progressWrapper {
  height: 100%;
  display: grid;
  place-items: center;
}
