.wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.disableInteractionWrap {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.container {
  position: absolute;
  inset: 0;
  overflow: auto;
  touch-action: pan-x pan-y;

  .viewer-wrapper {
    overflow: hidden;
    min-width: max-content;
    position: relative;

    .viewer {
      padding: 1rem 0;

      :global .page {
        box-sizing: content-box;
        position: relative;
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.35));
        margin: 0 auto 1rem auto;
        background: white;
        background-clip: content-box;

        &:last-child {
          margin: 0 auto;
        }
      }

      :global .textLayer {
        br::selection {
          background: transparent;
        }
      }
    }
  }
}
