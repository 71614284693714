@use '@work4all/assets/theme/variablesLight.scss';
@use '@work4all/assets/theme/breakpoints.scss';
@use '@work4all/assets/theme/typography.scss';
@use '@work4all/assets/theme/mixins.scss';

:global {
  .mbsc-ios.mbsc-event.mbsc-list-item {
    padding: 0.25rem 0.5rem;

    &:first-child {
      margin-top: 0.5rem;
    }

    &:last-child {
      margin-bottom: 0.5rem;
    }

    &::before,
    &::after {
      display: none;
    }
  }
}

.event {
  margin: 0.0625rem;
  height: 100%;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  padding: 0.05rem 0.25rem;
  background: white;
  border-radius: 4px;
  border-left-style: solid;
  border-left-width: 4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;

  :global(.mbsc-ios.mbsc-schedule-all-day) & {
    height: 1.5rem;
    align-items: center;
  }
}

.calendarEvent {
  composes: event;
  padding: 0.125rem;
  align-items: center;
}

.agendaEvent {
  composes: event;
  width: 100%;
  padding: 0.125rem;
  min-height: 2.5rem;
  align-items: center;
}

.eventBackground {
  position: absolute;
  inset: 0;
  background-color: currentColor;
  opacity: 0.3;
  transition: 200ms opacity ease;

  :is(
      :global(.mbsc-schedule-event-active),
      :global(.mbsc-schedule-event-active),
      :global(.mbsc-calendar-label-hover),
      :global(.mbsc-calendar-label-hover)
    )
    & {
    opacity: 0.4;
  }
}

.eventContent {
  flex: 1;
  min-width: 0;
  overflow: hidden;
}

.eventTitle {
  @include typography.caption;
  color: variablesLight.$text01;
  white-space: nowrap;
}

.eventTitleBig {
  @include typography.caption;
  color: variablesLight.$text01;
  max-height: 3em;
  line-height: 1.5em;
  white-space: wrap;
  overflow: hidden;
}

.eventInfo {
  @include typography.caption;
  color: variablesLight.$text03;
  white-space: nowrap;
}

.eventInfoBig {
  @include typography.caption;
  color: variablesLight.$text03;
  max-height: 3em;
  line-height: 1.5em;
  white-space: wrap;
  overflow: hidden;
}

.eventExtra {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  flex: none;
}

.eventPrivateIcon {
  flex: none;
  color: variablesLight.$text01;
  font-size: 16px;
}

.eventParticipants {
  flex: none;

  display: flex;
  align-items: center;

  :is(.calendarEvent, :global(.mbsc-ios.mbsc-schedule-event-all-day)) & {
    height: 0;
  }
}

.holiday {
  background: var(--bg-holiday) !important;
}

.calendar {
  --bg-holiday: repeating-linear-gradient(
    132deg,
    transparent,
    transparent 0.25rem,
    var(--ui02) 0.25rem,
    var(--ui02) 0.5rem
  );

  height: 100%;

  :global {
    .mbsc-timeline-resource-col {
      width: 3rem;
    }

    .mbsc-windows.mbsc-schedule-date-header-text {
      padding: 1rem 0;
    }

    .mbsc-timeline-resource-title {
      width: 100%;
      height: 100%;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .mbsc-schedule-event {
      color: variablesLight.$text01;
    }

    .mbsc-schedule-event-inner {
      position: static;
    }

    .mbsc-schedule-event-all-day-placeholder {
      height: 24px;
    }

    .mbsc-calendar-month-name,
    .mbsc-calendar-day-text {
      color: var(--text01) !important;
      border-color: transparent !important;
      background: transparent !important;
    }

    .mbsc-calendar-day.mbsc-selected {
      background: #499ce024 !important;
    }

    .mbsc-calendar-mark {
      background: var(--brand01);
    }

    .mbsc-event-list.mbsc-event-list-scroll {
      @media not screen and (min-width: breakpoints.$md) {
        padding-bottom: 5rem;
      }
    }
  }
}

.shrinkerContainer {
  position: relative;

  .shrinker {
    position: absolute;
    right: 1rem;
    z-index: 10;
    top: 2.5rem;

    @media (min-width: breakpoints.$lg) {
      top: 4.5rem;
    }

    &.expanded {
      top: 17.5rem;
      @media (min-width: breakpoints.$lg) {
        top: 16.5rem;
      }
    }
  }
}
