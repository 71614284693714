@use '@work4all/assets/theme/breakpoints.scss';

.task-status-cell-root {
  display: flex;
  align-items: center;
}

.check-circle {
  color: var(--text03);

  &.check-circle-success {
    color: var(--success);
  }

  &.disabled {
    color: var(--ui04);
  }
}

.label {
  display: none;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: breakpoints.$lg) {
    display: block;
  }
}
