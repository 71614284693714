.wrapper {
  background-color: var(--ui08);
  display: flex;
  height: 100%;

  flex-direction: column;
  > :last-child {
    flex: 1;
    min-height: 0;
  }
}
