@import 'libs/assets/theme/typography.scss';
@import 'libs/assets/theme/spacing.scss';

.heading {
  @include tableHeading;
  padding: 0;
  border-bottom: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-gap: $spaceXS;
  gap: $spaceXS;
  align-items: center;
  // grid-auto-columns: min-content;
}
