@use '@work4all/assets/theme/elevations.scss';

@use '@work4all/assets/theme/breakpoints.scss';

.widgets {
  position: relative;
  z-index: 100;
  margin: 0 1rem;
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @include elevations.elevationWidget;
  background-color: var(--ui01);
  overflow: hidden;

  & + & {
    margin-top: 1rem;
  }

  @media (min-width: breakpoints.$md) {
    margin-top: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    gap: 1rem;
    background-color: transparent;
    @include elevations.elevationNone;
  }

  @media (min-width: breakpoints.$lg) {
    grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  }
}
