@import 'libs/assets/theme/spacing.scss';

.header {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 500;
  position: fixed;
  height: var(--headerHeight);
  width: 100%;
  top: var(--w4a-safe-area-top, 0px);
  overflow: hidden;
  transform: translateZ(
    0
  ); //moved the layer to the gpu rendering, elsewise the background images clippath was interferring see #956 in jiira
  padding-right: 1rem;
  &__left,
  &__right {
    display: flex;
    align-items: center;
  }

  &__left {
    flex: 1;
    overflow: hidden;
  }

  &__right {
    flex: 0;
    padding: 1rem 0;
    > * {
      margin-right: 0.75rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
