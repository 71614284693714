@import '../../../../../variables.scss';
@import '@work4all/assets/theme/breakpoints.scss';

@media (max-width: $sm) {
  .mobileVert {
    display: flex;
    flex-direction: column;
  }
}

.root {
  padding-right: $sectionHorizontalPadding;
}
