@import 'libs/assets/theme/spacing.scss';

.infoCardsWrapper {
  gap: $spaceS;
  display: flex;
  flex-direction: column;
  padding: 0 $spaceS $spaceS $spaceS;
}

.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: var(--w4a-safe-area-bottom, 0px);
}

.content {
  flex-grow: 1;
  overflow: auto;
  border: none;
}

.blueAction {
  color: var(--brand01);
}

.attachmentsWrapper {
  padding: 1rem;
}
