.divider {
  padding: 1rem 0;

  &:after {
    width: 100%;
  }
}

.templatesGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: row;
  grid-gap: 1rem;

  &.mobile {
    grid-template-columns: repeat(2, 1fr);
  }
}

.moreButton {
  margin-top: 1rem;
}
