.button {
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem 1rem;
}

.actionsWrapper {
  display: flex;
}
