.banner {
  padding: 0.25rem 1rem;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  color: white;
  background: var(--error);
}
