@import '@work4all/app/containers/mask-overlays/mask-overlay/variables.scss';
@import 'libs/assets/theme/spacing.scss';

.wrapperInner {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
}

.root {
  width: 100%;
}

.collapseContainer {
  padding: 0 $sectionHorizontalPadding;
}
