.root {
  cursor: pointer;
  padding: 12px;
  display: flex;
  gap: 1rem;
}

.root:hover {
  background-color: var(--ui03);
}

.icon {
  width: 16px;
  height: 16px;
  color: var(--ui05);
}
