@use '@work4all/assets/theme/breakpoints.scss';
@use '@work4all/assets/theme/typography.scss';
@use '@work4all/assets/theme/mixins.scss';

.header {
  @include mixins.mainHeadDropshadow;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem;
  padding-top: calc(0.5rem + var(--w4a-safe-area-top, 0px));

  .goBack {
    color: var(--text01);
    min-height: 3rem;
    width: 2.5rem;
    min-width: 2.5rem;
  }
}
