.actions {
  padding: 0;
  opacity: 0;
}

.table-row {
  display: flex;
  &:hover {
    background: rgba(73, 156, 224, 0.08);
  }
  .drag-handler {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    padding: 0;
    color: var(--text03);
    svg {
      display: none;
      width: 21px;
      height: 21px;
    }

    &[draggable] {
      cursor: move;
    }
  }

  .drag-handler-simple {
    width: 6rem;
  }

  &:hover {
    .drag-handler {
      svg {
        display: unset;
      }
    }

    .actions {
      padding: 0;
      opacity: 1;
    }
  }
}

.disable-hover {
  &:hover {
    background: unset;
  }
}

.drag-empty {
  width: 40px;
}

.expand-empty {
  width: 40px;
}

.borderRight {
  border-right: 1px solid var(--ui04);
}

.noBorderHorizontal {
  border-top: unset;
  border-bottom: unset;
}

.noBorderVertical {
  border-left: unset;
  border-right: unset;
}
