.resizable {
  max-width: 50vw;
  min-width: min(50vw, min(100vw, 31.25rem));
}

.details {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: var(--ui01);
}
