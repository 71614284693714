@import '@work4all/assets/theme/spacing.scss';

.root {
  display: flex;
  align-items: center;
  padding: 0 $spaceS;
}

.input {
  background-color: var(--ui01);
  flex: 1;
  border: none;
  outline: none;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5;
  padding: 1rem 0;
}

.icon-override {
  width: 1rem;
  height: 1rem;

  path {
    fill: var(--text03);
  }
}
