@import '@work4all/assets/theme/spacing.scss';

.content {
  background-color: var(--ui03);
  height: 100%;
  overflow-y: auto;
}

.filterCardsWrap {
  display: flex;
  padding: $spaceS;
  gap: $spaceS;
  flex-direction: column;
}

.secondaryFiltersWrap {
  padding: $spaceS;
  padding-top: 0;
}

.filterNavLabel {
  flex-grow: 1;
}

.itemText {
  display: flex;
  width: 100%;
}

.filterValue {
  color: var(--text03);
}
