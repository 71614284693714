.link {
  display: flex;
  gap: 0.5rem;
  text-decoration: none;
  padding: 0 1rem;
  height: 2rem;
  align-items: center;

  &:hover {
    background-color: var(--ui03);
  }
}
