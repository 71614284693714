@import 'libs/assets/theme/spacing.scss';

.unit {
  height: $spaceM;
  margin-left: $spaceXS;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text03);
  align-self: flex-end;
  white-space: nowrap;
}
