.wrap {
  display: flex;
  align-items: center;
  gap: 1rem;
  text-align: center;
  padding: 0 1rem;
  flex-direction: row;
}

.icon {
  color: var(--text03);
  font-size: 2rem;
}

.mobile {
  flex-direction: column;
}
