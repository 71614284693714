.locker {
  &__container {
    position: relative;
    width: fit-content;
  }
  &__icon {
    background: white;
    position: absolute;
    bottom: 0;
    right: -8px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
