@import '@work4all/assets/theme/spacing.scss';

.toggleButton {
  background-color: var(--ui03);
  color: var(--text01);
  border-radius: $spaceXXS;
  text-align: center;
  border-radius: 0;
}

.maxWidth {
  width: 100%;
  border-radius: 0.25rem 0 0 0.25rem;
}

.active {
  background-color: var(--brand01);
  color: var(--textInverse);
}

.toggleButtonWrap {
  border-radius: 0.25rem;
  overflow: hidden;
}
