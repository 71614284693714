@use '@work4all/assets/theme/breakpoints.scss';
@use '@work4all/assets/theme/typography.scss';
@use '@work4all/assets/theme/variablesLight.scss';

.wrapper {
  @media (min-width: breakpoints.$lg) {
    max-width: 600px;
  }
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  box-shadow: 0 2px 12px 0 var(--elevationColor2);
}

.toolbar-left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.subTitle {
  @include typography.heading3;
  color: var(--text03);
}
