@import 'libs/assets/theme/spacing.scss';

.root {
  min-width: auto;
  min-height: auto;
  text-transform: unset;
  opacity: 1;
  padding: 0;
  margin: $spaceXS;
  padding: $spaceXS;
  color: var(--text02);
  font-size: $spaceS;
  line-height: $spaceM;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.error {
  color: var(--error) !important;
}

.selected {
  border-radius: $spaceXXS;
  background-color: rgba(73, 156, 224, 0.1);
  color: var(--brand01);
}

.badge {
  top: 0;
  right: 0;
  transform: none;
  transform-origin: 50% 50%;
}
