@use '@work4all/assets/theme/breakpoints.scss';
@use '@work4all/assets/theme/mixins.scss';

.header {
  @include mixins.mainHeadDropshadow;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: var(--w4a-safe-area-top, 0px);
  flex: none;

  height: calc(var(--headerHeight) + var(--w4a-safe-area-top, 0px));

  @media (min-width: breakpoints.$md) {
    padding: 1rem;
  }
}

.menuWrapper {
  width: 275px;
}
