.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.inverse {
    flex-direction: column-reverse;
  }
}

.picker {
  margin: 4px;
}
