.root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  isolation: isolate;
  padding-bottom: calc(5rem + var(--w4a-safe-area-bottom));
}

.toolbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  position: absolute;
  z-index: 1;
  left: 0px;
  right: 0px;
  bottom: var(--w4a-safe-area-bottom);
  background: linear-gradient(to top, var(--ui01), transparent);
}
