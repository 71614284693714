.panel {
  height: 100%;
  display: grid;
  grid-template-columns: 3fr auto 2fr;
}

.collapse {
  flex: 1;
  display: grid;
  grid-template-rows: auto 1fr;
}

.collapseRoot {
  display: grid;
}

.collapseWrapperInner {
  display: grid;
  grid-template-rows: 1fr;
}

.card {
  flex-grow: 1;
  display: grid;
  grid-auto-rows: auto 1fr;
}
