@import 'libs/assets/theme/spacing.scss';
@import 'libs/assets/theme/mixins.scss';

.root {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
}

.body {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: $spaceL;
  padding: 0 $spaceL;
}

.preview {
  display: inline-block;
  height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.footer {
  margin: $spaceL 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 $spaceL;
  overflow: hidden;
  width: 100%;
}

.meta {
  text-align: center;
  width: 100%;
  @include ellipsis;
}

.gap {
  margin: 0 $spaceXS;
}

.size {
  color: var(--text03);
}
