@use '@work4all/assets/theme/typography.scss';

.label {
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: var(--text03);
}

.days {
  @include typography.body1;
  color: var(--text02);
}

.info {
  @include typography.caption;
  color: var(--text02);
}
