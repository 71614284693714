@import '@work4all/assets/theme/spacing.scss';

.pickerWrap {
  padding: $spaceS;
  display: flex;
  flex-direction: column;
  gap: $spaceS;
}

.shortcuts {
  display: flex;
  flex-direction: column;
  gap: $spaceS;
}

.darkText {
  color: var(--text01);
}

.timeFramePlaceholder {
  height: $spaceXL;
}
