@use 'libs/assets/theme/variables.scss';

.default {
  fill: var(--text03);
}

.success {
  fill: var(--success);
}

.alert {
  fill: var(--alert);
}

.error {
  fill: var(--error);
}
