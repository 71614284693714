@import '@work4all/assets/theme/elevations.scss';

.entityPickerFieldButton {
}

.cellButtonWrapper {
  background-color: unset;
  border: none;
  padding: 0;
  height: 2rem;
  min-height: unset;
  margin: -0.25rem 0 0 0;
  &:hover {
    background: var(--ui02);
    border: 1px solid var(--ui04);
  }
}

.inputCellButton {
  height: unset;
}

.cellButton {
  padding: 0;
  justify-content: right;
}
