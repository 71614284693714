@use '@work4all/assets/theme/breakpoints.scss';

.collapse {
  flex: 1;
  display: grid;
  grid-template-rows: auto 1fr;
}

.collapseContainer {
  display: grid;
}

.collapseWrapperInner {
  display: grid;
  grid-template-rows: auto auto 1fr;
}

@media (max-width: breakpoints.$sm) {
  .mobileVert {
    display: flex;
    flex-direction: column;
  }
}
