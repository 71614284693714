.resource {
  padding: 0.5rem 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 0.5rem;
  height: 2rem;
  &:hover {
    background-color: #8585853f;
  }
}

.deselectButton {
  color: var(--ui05);
}
