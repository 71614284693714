.table {
  display: grid;
  grid-gap: 0px;
  grid-template-rows: auto 1fr;
  min-width: unset !important;
  overflow: clip;
  &:focus {
    outline: none;
  }
}
