@use '@work4all/assets/theme/spacing.scss';

.chipsContainer {
  display: flex;
  margin-top: spacing.$spaceS;
  min-height: spacing.$spaceM;
  flex-wrap: wrap;
  gap: spacing.$spaceXXS spacing.$spaceXS;
}

.container {
  position: relative;
}
