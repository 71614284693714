@mixin elevation1 {
  box-shadow: 0px 0px 2px 0px var(--elevationColor1);
}

@mixin elevation2 {
  box-shadow: 0px 1px 2px 0px var(--elevationColor2);
}

@mixin elevation3 {
  box-shadow: 0px 4px 8px 0px var(--elevationColor3);
}

@mixin elevation4 {
  box-shadow: 0px 6px 12px 0px var(--elevationColor4);
}

@mixin elevation5 {
  box-shadow: 0px 8px 16px 0px var(--elevationColor5);
}

@mixin elevation6 {
  box-shadow: 0px 10px 24px 0px var(--elevationColor6);
}

@mixin elevationWidget {
  box-shadow: 0px 2px 1rem 0px var(--elevationColor1);
}

@mixin elevationMainHead {
  box-shadow: 0px 2px 12px 0px var(--elevationColor2);
}

@mixin elevationNone {
  box-shadow: none;
}
