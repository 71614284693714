@use '@work4all/assets/theme/elevations.scss';

.backdrop {
  background-color: rgba(255, 255, 255, 0.8);

  @supports (backdrop-filter: blur(15px)) {
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(15px);
  }
}

.root {
  @include elevations.elevation5;

  max-width: 426px;
  padding: 0 1rem;
  background-color: var(--ui01);
  border-radius: 0.5rem;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  color: var(--brand01);
  width: 70px;
  height: 70px;
  margin: 1rem;
  flex: none;
}

.title {
  margin: 1rem;
}

.description {
  margin: 1rem;
}

.actions {
  display: flex;
  margin: 0 -1rem 0 -1rem;
}
