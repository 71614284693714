.indicator {
  border-radius: 360px;
  padding: 0px 5px 0px 5px;
  height: 20px;
  min-width: 20px;
  font-size: 0.85rem;
  color: var(--textInverse);
  text-align: center;
  vertical-align: middle;
  line-height: 20px;
  overflow: hidden;
}

.primaryIndicator {
  background-color: var(--brand02);
}

.secondaryIndicator {
  background-color: #79b7ea;
}
