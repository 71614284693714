.colleaguesTooltipList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 0.25rem;
}

.colleaguesTooltipItem {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
