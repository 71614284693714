.mobile-wrapper {
  height: 100%;
  padding-bottom: calc(4rem + var(--w4a-safe-area-bottom));
  overflow: auto;
  position: fixed;
  width: 100%;
}

.mobile-wrapper-list-view {
  height: 100%;
  padding-bottom: var(--w4a-safe-area-bottom);
  overflow: auto;
}
