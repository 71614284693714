.addFileMenu {
  min-width: 340px;
}

.icon {
  color: var(--text01);
}

.item {
  padding-left: 2rem;
}

.divider {
  padding: 0.75rem 1rem;
}

.leftBottom {
  position: fixed;
  bottom: 3rem;
  left: calc(3.5rem + var(--w4a-safe-area-left));
  @media (max-width: 767px) {
    bottom: 8rem;
  }
}

.rightBottom {
  position: fixed;
  bottom: 3rem;
  right: calc(3.5rem + var(--w4a-safe-area-left));
  @media (max-width: 767px) {
    bottom: 8rem;
  }
}

.noLinkStyles {
  text-decoration: none;
  color: unset;
}
