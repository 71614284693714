@import 'libs/assets/theme/spacing.scss';

.root {
  margin: 0;
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.bold {
  font-weight: bold;
}

.flex {
  display: flex;
  align-items: center;
}

.icon {
  gap: 0.5rem;
}

.horizontalPadding {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.verticalPadding {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.controlMargin--xs {
  margin-right: $spaceXS;
}

.controlMargin--s {
  margin-right: $spaceS;
}

.radio {
  color: var(--text02);
}

.checkbox {
  color: var(--text01);
}

.label {
  line-height: unset;
  letter-spacing: unset;
  width: 100%;
}

.body {
  font-size: 1rem;
}

:global(.Mui-disabled) {
  .body {
    color: var(--text03) !important;
  }
}

.caption {
  color: #8b90a0;
}

.fitContent {
  width: fit-content;
}
