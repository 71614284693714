.container {
  width: 252px;
  padding: 16px;

  .radioButtons {
    margin-top: 16px;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
}
