.rootPopover {
  pointer-events: none;
}

.container {
  width: 392px;
  background-color: #000;
  padding: 12px;

  &.sm {
    width: fit-content;
  }
}

.row {
  display: flex;
  justify-content: space-between;
}

.key {
  min-width: 72px;
  max-width: 72px;
}

.value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 232px;
}
