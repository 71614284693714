.outerWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  pointer-events: none;
}

.box {
  background-color: var(--ui01);
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  pointer-events: initial;
}

.title {
  align-self: center;
}

.subtitle {
  color: var(--text03);
}

.divider {
  padding: 0.5rem 0;
}

.top {
  display: flex;
  justify-content: space-between;

  .phoneIcon {
    background: #ffffff;
    border: 0.3px solidvar(--text03);
    box-shadow: 0px 1px 2px var(--elevationColor2);
    border-radius: 2px;
    margin-right: 8px;
    width: 44px;
    height: 44px;
  }
}

.phoneNumberContainer {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.5rem;

  .button {
    width: 100%;
    justify-content: flex-start;
    padding: 0.5rem;
  }
}
