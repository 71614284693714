.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  font-weight: 700;
}

.center {
  justify-content: center;
}

.left {
  justify-content: left;
}

.right {
  justify-content: right;
}
