@import 'libs/assets/theme/mixins.scss';

.iconWrapper {
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
}

.sheet {
  width: 1.125rem;
  height: 100%;
  margin: auto;
  background-color: var(--ui04);
  border-radius: 1px;
  position: relative;
  &::before {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }
}
.ribbon {
  @include ellipsis;
  position: absolute;
  max-width: 2rem;
  margin-left: -0.25rem;
  font-size: 0.5rem;
  font-weight: 700;
  line-height: 0.5rem;
  padding: 2px;
  background-color: var(--ui04);
  font-family: 'Roboto';
  text-transform: uppercase;
  top: 50%;
  margin-top: calc((0.5rem + 4px) / 2 * -1);
}

.blue {
  .sheet,
  .ribbon {
    background-color: var(--brand01);
    color: var(--textInverse);
  }
}
.green {
  .sheet,
  .ribbon {
    background-color: var(--success);
    color: var(--textInverse);
  }
}
.red {
  .sheet,
  .ribbon {
    background-color: var(--error);
    color: var(--textInverse);
  }
}
.yellow {
  .sheet,
  .ribbon {
    background-color: var(--brand02);
    color: var(--textInverse);
  }
}
.purple {
  .sheet,
  .ribbon {
    background-color: var(--purple);
    color: var(--textInverse);
  }
}
