.statusMessage {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 9px;

  .icon {
    margin-right: 9px;
  }

  &.error {
    background-color: var(--error);
  }

  .error {
    color: var(--textInverse);
  }

  &.draft {
    background-color: var(--brand02);
  }

  .draft {
    color: var(--text01);
  }

  &.sending {
    background-color: var(--ui06);
  }

  .sending {
    color: var(--textInverse);
  }

  .action {
    color: var(--textInverse);
    text-decoration-color: var(--textInverse);
  }
}

.sendingIcon {
  float: left;
}
