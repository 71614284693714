.root {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 10px;
  align-items: center;

  color: var(--text03);
  font-size: 0.85rem;

  padding: 4px 16px;
  box-sizing: border-box;
}

.bold {
  font-weight: 900;
}
