@import 'libs/assets/theme/spacing.scss';

@import 'libs/assets/theme/elevations.scss';

.wrap {
  @include elevationWidget;
  padding: $spaceS $spaceS;
  background-color: var(--ui01);
  border-radius: var(--borderRadius);
}

.no-padding {
  padding: 0;
}
