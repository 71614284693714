.content {
  padding: 1rem;
  margin-bottom: 0px;
}

.wrap {
  display: flex;
  align-items: center;
}

.title {
  flex-grow: 1;
  padding-left: 1rem;
}

.button {
  flex-grow: 1;
}

.noPadding {
  padding: 0;
  align-content: center;
}

.actions {
  height: 3rem;
}
