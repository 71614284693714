.mobileToolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 10;
  padding: 1rem;
  left: 0px;
  right: 0px;
  bottom: 0px;
  pointer-events: none;

  .inner {
    display: flex;
    gap: 1rem;
    align-items: center;
    pointer-events: all;
  }
}
