@import 'libs/assets/theme/elevations.scss';
@import 'libs/assets/theme/spacing.scss';

.cameraRoot {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 0.75rem;
}

.cameraCloseButton {
  position: absolute;
  border-radius: $spaceM;
  @include elevation3;
  padding: 0.75rem;
  right: $spaceXS;
  top: $spaceXS;
  color: #fff;
  background-color: var(--brand01);
}
