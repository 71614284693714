@use '@work4all/assets/theme/elevations.scss';

.root {
  padding: 0px 1rem 0.5rem 0px;
  background: var(--ui01);
  box-sizing: border-box;
  @include elevations.elevationWidget;
  border-radius: 0.5rem;
}

.name {
  padding: 1rem 0px 0.5rem 3rem;
  font-weight: 900;
  font-size: 1rem;
  line-height: 1;

  &.draggable {
    cursor: pointer;
  }
}
