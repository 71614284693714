.dragBox {
  position: absolute;
  cursor: move;
  font-size: 0.75rem;
  font-family: monospace;
  border: 1px dashed grey;
  display: flex;
  background-color: white;
}

.textmarkWrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.textmarkInnerWrap {
  position: relative;
  height: 100%;
  width: 100%;
}

.deleteButton {
  height: 1.5rem;
  width: 1.5rem;
}

.textContainer {
  color: black;
  padding: 0 0 0 0.25rem;
}
