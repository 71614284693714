.row {
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  height: 100%;
  width: 100%;
  display: flex;
  background-color: var(--ui01);

  &:hover {
    background-color: var(--ui02);
  }
  &--selected:hover {
    background-color: var(--ui08);
  }
}
.card {
  padding: 0.75rem 0.5rem;
  display: grid;
  align-items: start;
  overflow: hidden;
  grid-template-columns: auto 1fr;
  grid-template-rows: max-content 1fr max-content;
  grid-template-areas:
    'icon title   secondary-icons'
    'icon content secondary-icons'
    'icon info    secondary-icons';
  [data-sticky-last-left-td]::after {
    display: none;
  }

  [data-sticky-first-right-td]::before {
    display: none;
  }
}

.faded {
  text-decoration: line-through;
  color: var(--text03);
}

.bold {
  font-weight: 700;
}

.bgShade1 {
  color: var(--text03);
}

.bgShade2 {
  background-color: var(--bgShade2);
}

.bgShade3 {
  background-color: var(--bgShade3);
}

.bgShade4 {
  background-color: var(--bgShade4);
}

.bgShade5 {
  background-color: var(--bgShade5);
}

.bgShade6 {
  background-color: var(--bgShade6);
}

.grouped {
  border-bottom: 0.0625rem solid var(--ui04);
  background-color: var(--ui02);
}

.row {
  &--selected {
    background-color: var(--ui08);
  }
}
