.list {
  list-style: none;

  @media (min-width: 600px) {
    columns: 2;
    column-gap: 0;
  }

  @media (min-width: 1280px) {
    columns: 3;
    column-gap: 0;
  }
}

.name {
  padding: 1.5rem 0px 0.5rem 3rem;
  color: var(--text01);
}
