.preview-wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  > :first-child {
    flex: 1;
    background-color: var(--ui08);
  }
  > :last-child {
    border-top: 1px solid var(--ui04);
    padding-top: 1rem;
    flex: 0.5;
  }
}
