@use '@work4all/assets/theme/breakpoints.scss';
@use '@work4all/assets/theme/mixins.scss';
@use '@work4all/assets/theme/elevations.scss';
@use '@work4all/assets/theme/typography.scss';

// Keep the list item height in sync with the normal picker.
$listItemHeight: 50px;

.root {
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.search {
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
}

.row {
  @include typography.body1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  height: $listItemHeight;
}

.mark {
  flex: none;
  width: 1.5rem;
}

.number {
  flex: none;
  width: 1.75rem;
}

.text {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.amount {
  flex: none;
  width: 3.25rem;
  text-align: right;
}

.unit {
  flex: none;
  width: 3.25rem;
}

.highlight {
  background: var(--ui02);
  outline: none;
}
