@use '@work4all/assets/theme/breakpoints.scss';

.root {
  display: flex;
}

.panel {
  flex: 1;
}

.history [class*='container'] {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 5rem;

  @media (min-width: breakpoints.$lg) {
    padding-bottom: 1rem;
  }
}
.textarea {
  min-height: 10rem;
}

.addSolutionBtn {
  margin-left: 2.5rem;
}

.solutionText {
  border-radius: 0.25rem;
  border-left: 0.25rem solid var(--success);
}

.title {
  flex: 1;
}

.date {
  flex: 1;
  display: flex;
  gap: 1rem;
}

.flexItem {
  flex: 1;
}

.rightHeader {
  padding-left: 0;

  @media (max-width: breakpoints.$md) {
    padding-left: 1rem;
  }
}

.checkListRoot {
  padding-left: 1rem;
}

.errorText {
  color: var(--error) !important;
}

.header {
  font-weight: bold;
  color: var(--text01);
  padding: 1rem 0;
}

.estimationWrapper {
  padding: 1rem 0;
}
