@import 'libs/assets/theme/spacing.scss';

.menu {
  width: 512px;
  max-width: 80%;
}

.pointer {
  cursor: pointer;
}
