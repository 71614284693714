@use '@work4all/assets/theme/breakpoints.scss';

.container {
  padding: 0 1rem 1rem 1rem;
  background-color: var(--ui03);
  border-top: 1px solid var(--ui04);
  border-bottom: 1px solid var(--ui04);
  @media (max-width: breakpoints.$sm) {
    max-height: 60vh;
    overflow: scroll;
  }
}

.checkbox {
  padding: 0;
  display: flex;
  align-items: start;
}

.info {
  color: var(--brand01);
}

.dialog {
  max-width: min(100vw, 626px);
}

.bomContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding-top: 1rem;
  @media (max-width: breakpoints.$sm) {
    grid-template-columns: 1fr;
  }
}

.dialogFooter {
  padding: 2rem 1rem;
}
