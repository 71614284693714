@import 'libs/assets/theme/spacing.scss';

.title {
  font-weight: 700;
  color: var(--text02);
  font-size: 1rem;
  height: 100%;
  margin-left: $spaceXS;
}

.count {
  margin-left: 0.5rem;
}
