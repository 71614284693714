@import 'libs/assets/theme/spacing.scss';
@import 'libs/assets/theme/elevations.scss';
@import 'libs/assets/theme/mixins.scss';

$alertMaxWidth: 23.4rem;

:export {
  alertMaxWidth: $alertMaxWidth;
}

.root {
  @include elevation5;
  border-radius: 0.75rem;
  @media (min-width: calc($alertMaxWidth + (2 * var(--modalMarginThreshold)))) {
    min-width: $alertMaxWidth;
  }
}

.header {
  display: flex;
  margin-bottom: $spaceXS;
  width: 100%;
  padding: $spaceS;
}

.body {
  margin-bottom: $spaceXS;
  padding: $spaceS;
  padding-top: 0;
  padding-bottom: 0.75rem;
}

.footer {
  display: flex;
}

.heading {
  text-transform: initial;
  flex: 1;
  font-size: 1rem;
  letter-spacing: initial;
}

.title {
  font-size: 1rem;
  max-width: 320px;
}

.caption {
  grid-column: 2;
  color: var(--text03);
}

.actionButton {
  flex: 1;
  font-size: 1rem;
  font-weight: normal;
  text-transform: capitalize;
  margin: 0;
}

.reject {
  color: var(--error);
}

.grant {
  color: var(--brand01);
}

.close {
  color: #a1a4b1;
}
