.link {
  text-decoration: none;
  &__body {
    color: var(--brand01);
    font-weight: 400;
  }

  &:hover {
    text-decoration: underline;
  }
}
