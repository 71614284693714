@import '@work4all/assets/theme/spacing.scss';

@import '@work4all/assets/theme/mixins.scss';

.content {
  background-color: var(--ui03);
  min-height: 100%;
}

.filterCardsWrap {
  display: flex;
  padding: $spaceS;
  gap: $spaceS;
  flex-direction: column;
}

.secondaryFiltersWrap {
  padding: 0 $spaceS;
  padding-bottom: 0;
}

.filterNavLabel {
  flex-grow: 1;
}

.itemText {
  display: flex;
  width: 100%;
}

.filterValue {
  color: var(--text03);
}
