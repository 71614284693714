// Select all checkboxes in the table's body (but not in the header).

@media (hover: hover) {
  [role='rowgroup'] [role='row'] .checkbox {
    opacity: 0;
  }

  [role='rowgroup'] [role='row']:hover .checkbox,
  [role='rowgroup'] [role='row'] .checkbox.checked {
    opacity: 1;
  }
}
