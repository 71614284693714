@import 'libs/assets/theme/mixins.scss';

.root {
  border-top: 2px solid var(--ui04);
  overflow: hidden scroll;

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar {
    width: auto;
  }
}
