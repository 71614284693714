.root {
  display: flex;
  flex-wrap: nowrap;
  gap: 0.5rem;
  align-items: center;
  padding-left: 1rem;
  color: var(--textInverse);

  &.isButtonMode {
    width: max-content;
    min-height: 3.25rem;
    border-radius: 0.25rem;
    border: 1px solid var(--textInverse);

    &:not(.disabled) {
      &.initial {
        background-color: var(--errorLight);
        border: 1px solid var(--error);
        color: var(--error);
      }

      &.running {
        background-color: transparent;
      }

      &.paused {
        background-color: transparent;
      }

      &.stopped {
        background-color: transparent;
      }
      &.warning {
        background-color: var(--errorLight);
        border: 1px solid var(--error);
        color: var(--error);
      }
    }
  }

  &.cardHeader {
    color: var(--text03);
    border: none;
    min-height: unset;

    &:not(.disabled) {
      &.initial {
        background-color: transparent;
        border: none;
        color: var(--error);

        .button {
          color: var(--error);
        }
      }

      &.warning {
        background-color: transparent;
        border: none;
        color: var(--error);
      }
    }

    .button {
      color: var(--text01);
    }
  }

  &.disabled {
    background-color: var(--ui04);
    color: var(--text03);
  }

  &:not(.disabled) {
    &.initial {
      background-color: var(--error);
    }

    &.running {
      background-color: var(--success);
    }

    &.paused {
      background-color: var(--alert);
    }

    &.stopped {
      background-color: var(--ui06);
    }
  }
}

.label {
  margin-right: auto;
  font-weight: 400;
}

.button {
  padding: 0;
  min-width: 1.5rem;
}
