.grid {
  display: grid;
  grid-template-rows: auto 1fr;

  overflow: auto;
}

.pickerDisabled {
  opacity: 0.5;
}

.toolbar {
  border-bottom: 1px solid var(--ui04);
  display: flex;
  align-items: center;

  .search {
    width: 18rem;
    position: relative;
    margin: 0 1rem 0 1rem;
    border: 1px solid var(--ui04);
    border-radius: 4px;
    height: 2.5rem;

    .searchIcon {
      pointer-events: none;
      position: absolute;
      left: 0.5rem;
      top: 50%;
      transform: translate(0, -50%);
      color: var(--text03);
    }

    .searchInput {
      width: 100%;
      height: 100%;
      outline: none;
      padding: 1rem 2.25rem;
      font-size: 1rem;
      line-height: 1.5rem;
      border: none;
      background-color: var(--ui01);
      color: var(--text01);
      border-radius: 4px;
    }
  }

  .searchClear {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    color: var(--text03);
  }
}

.actions {
  flex: none;
  padding-right: 16px;
  display: flex;
  align-items: center;
  height: 100%;
}

.tableContainer {
  overflow: auto;
  flex: 1;
  margin-top: -1px;
}

.groupsContainer {
  overflow-y: auto;
  overflow-x: hidden;
}

.table {
  height: 100%;
  border-left: 1px solid var(--ui04);
}

.tableDisabled {
  cursor: not-allowed;
}

.treeGroup {
  margin-left: 24px;
}

.tableWrapper {
  display: flex;
  overflow: auto;
}

.background {
  background-color: var(--ui03);
}

.chip-background {
  background-color: var(--ui04);
}
