@import 'libs/assets/theme/spacing.scss';
@import '../../../../../../../variables.scss';

.right {
  flex: 1;
  padding-right: $sectionHorizontalPadding;
}

.uploadWrapper {
  padding-left: $sectionHorizontalPadding;
  padding-bottom: 2rem;
}

.mobile {
  order: 1;
  padding-right: 0;
  padding-top: 0;
}
