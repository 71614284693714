.treeItem {
  color: var(--text01);
  .content {
    padding: 0.4rem 0.5rem;
  }

  .group {
    margin-left: 2rem;
  }

  .iconContainer {
    padding: 8px 4px;
    margin: -8px 0;
    width: 32px;

    svg {
      font-size: 1.5rem;
      color: var(--text03);
    }
  }

  .selected {
    .label {
      color: var(--brand01);
    }
  }
}

.content {
  &:hover {
    .drag-handler {
      svg {
        display: unset;
      }
    }
  }

  .drag-handler {
    display: flex;
    width: 3rem;
    color: var(--text03);
    svg {
      display: none;
      width: 1.5rem;
      height: 1.5rem;
    }

    cursor: move;
  }
}

.label {
  color: var(--text01);
}
