.item {
  border-bottom: 1px solid var(--ui03);
}

.checkIcon {
  background: white;
  color: var(--success);
  width: 1rem;
  height: 1rem;
  display: block;
  border-radius: 10rem;
}
