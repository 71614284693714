.root {
  padding: 1rem;

  &.dark {
    background: rgba(0, 0, 0, 0.11);
    backdrop-filter: blur(27px);
    border-radius: 0.5rem;
  }
}

.divider {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-color: var(--widgetBorder);
}

.list {
  list-style: none;
}

.link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: var(--text01);
  text-decoration: none;
  appearance: none;
  width: 100%;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-align: start;

  .dark & {
    color: var(--textInverse);
  }

  &.active {
    color: var(--brand01);
    background-color: var(--ui01);
    font-weight: 700;
  }
}

.count {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  height: 20px;
  min-width: 20px;
  border-radius: 10px;
  padding-left: 4px;
  padding-right: 4px;
  background: var(--brand02);
  color: var(--textInverse);
}
